<template>
  <div class="empty-state">
    <div v-if="isChannelListing" class="widget--listing">
      <img src="@/assets/images/empty-state/channel.svg" alt="" />
      <div class="copy">
        <p>So many channels to</p>
        <p>choose from...</p>
      </div>
    </div>
    <div v-if="isBilling" class="widget--listing billing--empty-state">
      <img src="@/assets/images/empty-state/empty.webp" loading="lazy" alt="" />
      <div class="copy" v-if="isShopifyUser">
        <p>You can download your invoices</p>
        <p>
          on the
          <a :href="`https://${store[0].attributes.url}/admin/settings/billing`" target="_blank" class="text-purple">Shopify billing</a>
          page
        </p>
      </div>
      <div class="copy" v-else-if="isDudaUser">
        <p>Your invoices will be emailed</p>
        <p>to you</p>
      </div>
      <div class="copy" v-else-if="isPrestashopUser">
        <p>Your invoices are managed through the</p>
        <p>PrestaShop billing system</p>
      </div>
      <div class="copy" v-else-if="isBilledOnSquare">
        <p>Your invoices are managed through the</p>
        <p>Square billing system</p>
      </div>
      <div class="copy" v-else>
        <p>Your invoices will</p>
        <p>appear here</p>
      </div>
    </div>
    <div class="home--state" v-if="isHome">
      <HomePlaceholder />
    </div>
    <div class="widgets--state" v-if="isWidgetListing">
      <WidgetListingPlaceholder />
    </div>
    <div class="leads--state" v-if="isLeads">
      <LeadsPlaceholderEmpty v-if="hasWidget" />
      <LeadsPlaceholderNoWidget v-else />
    </div>

    <div class="billing" v-if="isSquareShop">
      <div class="billing-right">
        <div class="invoices">
          <div class="widget--listing billing--empty-state">
            <img src="@/assets/images/empty-state/empty.webp" loading="lazy" alt="" />
            <div class="copy">
              <p>Your shops will</p>
              <p>appear here</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HomePlaceholder from '@/components/placeholders/HomePlaceholder.vue'
import WidgetListingPlaceholder from '@/components/placeholders/WidgetListingPlaceholder.vue'
import LeadsPlaceholderEmpty from '@/components/placeholders/LeadsPlaceholderEmpty.vue'
import LeadsPlaceholderNoWidget from '@/components/placeholders/LeadsPlaceholderNoWidget.vue'
export default {
  components: {
    HomePlaceholder,
    WidgetListingPlaceholder,
    LeadsPlaceholderNoWidget,
    LeadsPlaceholderEmpty
  },
  props: {
    isChannelListing: {
      type: Boolean,
      default: false
    },
    isWidgetListing: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    },
    isBilling: {
      type: Boolean,
      default: false
    },

    isLeads: {
      type: Boolean,
      default: false
    },
    hasWidget: {
      type: Boolean,
      default: false
    },
    isSquareShop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      store: 'user/getShopifyStore'
    })
  }
}
</script>

<style></style>
