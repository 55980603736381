export default class WidgetService {
  constructor(args) {
    this.axios = args.$axios
  }

  getAllWidgets(startDate = null, endDate = null) {
    if (!startDate && !endDate) {
      return this.axios.get(`widgets`)
    } else {
      // return this.axios.get(`widgets`)
      return this.axios.get(`widgets?filter[start_date]=${startDate}&filter[end_date]=${endDate}`)
    }
  }

  getConnectedChannel(channel) {
    return this.axios.get(`apps/connected?channel=${channel}`)
  }

  getWidget(id) {
    return this.axios.get(`widgets/${id}?include[]=settings&include[]=channels&include[]=triggers`)
  }

  deleteWidget(id) {
    return this.axios.delete(`widgets/${id}`)
  }

  createWidget({ name, domain }) {
    return this.axios.post(`widgets`, {
      name,
      domain,
      status: 0
    })
  }

  updateWidget({ name, channels, triggers, settings, id, published }) {
    return this.axios.patch(`widgets/${id}`, {
      name,
      published,
      channels,
      triggers,
      settings
    })
  }

  updateWidgetDomain({ domain, id }) {
    return this.axios.put(`widgets/${id}/domain`, {
      domain
    })
  }

  renameWidget({ name, id }) {
    return this.axios.patch(`widgets/${id}/rename`, {
      name
    })
  }

  updateContactForm({ widgetId, contactFormId, form_fields }) {
    return this.axios.patch(`widget/${widgetId}/contactform/${contactFormId}`, {
      form_fields
    })
  }

  duplicateWidget({ name, domain, id }) {
    return this.axios.post(`widgets/${id}/duplicate`, {
      name,
      domain
    })
  }

  changePublishStatus(id, status) {
    return this.axios.post(`widgets/${id}/publish?publish=${status}`)
  }
}
