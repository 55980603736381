<template>
  <div class="auth-providers">
    <div class="auth-providers__title">
      <span class="text-holder">
        <slot></slot>
      </span>
    </div>
    <a href="#" @click.prevent="authVia('google')" class="auth-providers__item auth-providers__item--google">
      <img src="/images/svg/google.svg" alt="google" width="25" height="24px" loading="lazy" />
      <span class="auth-providers__text">Google</span>
    </a>
    <div class="auth-providers__grid">
      <a href="https://www.wix.com/installer/install?appId=94b38ecb-558d-4b2d-8deb-8901ab73ef4d&platform=standalone" target="_blank" class="auth-providers__item auth-providers__item--wix">
        <img src="/images/svg/wix.svg" alt="wix" width="25" height="24px" loading="lazy" />
        <span class="auth-providers__text">Wix</span>
      </a>
      <a href="https://apps.shopify.com/chaty/install" target="_blank" class="auth-providers__item auth-providers__item--shopify">
        <img src="/images/svg/shopify.svg" alt="shopify" width="25" height="24px" loading="lazy" />
        <span class="auth-providers__text">Shopify</span>
      </a>
    </div>
  </div>
</template>
<script>
import { AuthService } from '@/services/api/index'

export default {
  name: 'AuthProviders',
  methods: {
    async authVia(provider) {
      localStorage.clear()
      this.$emit('updateLoadingStatus', true)

      try {
        const { data } = await new AuthService(this).getProviderURL(provider)
        window.location.href = data.attributes
      } catch (error) {
        this.$store.commit('toast/update', { message: error.message || 'An error occurred', type: 'error', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: error.message || 'An error occurred', type: 'error', status: false })
      } finally {
        this.$emit('updateLoadingStatus', false)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.auth-providers {
  max-width: 360px;
  width: 100%;
  text-align: center;

  &__title {
    padding: 24px 0;
    position: relative;
    isolation: isolate;

    .text-holder {
      display: inline-block;
      background-color: #fff;
      padding: 0 12px;
      z-index: 1;
      line-height: 20px;
      font-size: 14px;
      color: #83a1b7;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #eaeff2;
      z-index: -1;
    }
  }

  &__grid {
    display: grid;
    column-gap: 8px;
    margin-top: 8px;
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    border: 1px solid #c6d7e3;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f8;
    }
  }

  &__text {
    font-size: 16px;
    color: #83a1b7;
  }
}
</style>
