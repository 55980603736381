import Vue from 'vue'
// Importing Root App
import App from './App.vue'

// Importing Router
import router from './router'

// Importing Vue store
import store from './store'

// Importing Axios
import { $axios } from './plugins/axios/index'

// Importing global mixins
import GlobalMixin from './plugins/mixins/dashboard'
import UtilMixin from './plugins/mixins/utils'

// Importing global components
import Spinner from '@/components/global/ChatySpinner.vue'
import EmptyState from '@/components/global/EmptyState.vue'
import Placeholder from '@/components/global/Placeholder.vue'

// web-vitals
import { loadHeaderTemplateAfterFCPCount, reportWebVitals } from '@/services/functions/web-vitals'
// Plugins

// Importing dayjs
import dayjs from 'dayjs'

// Vue Meta
import VueMeta from 'vue-meta'

// Importing Vuelidate
import Vuelidate from 'vuelidate'

/**
 * Importing sweet modal
 * @learn https://sweet-modal-vue.adepto.as/
 */
import SweetModal from 'sweet-modal-vue/src/plugin.js'

// Importing calender and time plugin
import VueFlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// Vue tel input
import VueTelInput from 'vue-tel-input'

// import { MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns'
// import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns'

// Importing vue apex chart
import VueApexCharts from 'vue-apexcharts'

// Importing style
import '@/assets/scss/main.scss'

import i18n from './i18n'

// Adding axios to vue instance
Vue.prototype.$axios = $axios

Vue.config.productionTip = false

// Plugins
Vue.use(VueMeta)
Vue.use(VueFlatPickr)
Vue.use(SweetModal)
Vue.use(Vuelidate)
// MultiSelect.Inject(CheckBoxSelection)
// Vue.use(MultiSelectPlugin)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueTelInput)

// Mixins
Vue.mixin(GlobalMixin)
Vue.mixin(UtilMixin)

// Filters
Vue.filter('formatDate', function (value) {
  return dayjs(value).format('MM/DD/YYYY')
})

Vue.filter('formatDateTime', date => {
  return dayjs(date).format("DD MMM'YY | hh:mm A")
})

// Components
Vue.component('Spinner', Spinner)
Vue.component('EmptyState', EmptyState)
Vue.component('Placeholder', Placeholder)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    reportWebVitals()
    loadHeaderTemplateAfterFCPCount()
  }
}).$mount('#app')
