<template>
  <sweet-modal overlay-theme="dark" :blocking="isLoading" class="ch-modal" ref="modal" @close="resetForm">
    <div class="sweet-content__header" :class="{ 'is-validating': isLoading }">
      <h5 class="header ch-header">{{ $t('title.change_domain') }}</h5>
    </div>

    <form action="" @submit.prevent="changeDomain">
      <div class="sweet-content__content" :class="{ 'is-validating': isLoading }">
        <div class="modal-details">
          <!-- Domain Field -->
          <div class="input-field__wrapper">
            <label for="widget_name">{{ $t('change_domain.enter_your_desired_domain') }}</label>
            <input type="text" id="domain" name="domain" v-model="form.domain" @blur="$v.form.domain.$touch()" :class="{ error: $v.form.domain.$error }" />
            <div v-if="$v.form.domain.$dirty">
              <span class="input-field__message error" v-if="!$v.form.domain.required">{{ $t('validation.required.domain') }}</span>
            </div>
            <div v-if="errors && errors.domain">
              <span class="input-field__message error" v-for="error in errors.domain" :key="error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="sweet-content__footer button--right">
        <div class="grid end-xs middle-xs">
          <button type="button" @click="$refs.modal.close()" class="button--outline button--secondary">{{ $t('buttons.cancel') }}</button>
          <button type="submit" class="button--outline" :disabled="disabled">{{ $t('buttons.change_domain') }}</button>
        </div>
      </div>
    </form>
  </sweet-modal>
</template>

<script>
import { WidgetService } from '@/services/api/index'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ChangeDomainWidgetModal',
  props: ['id'],
  validations: {
    form: {
      domain: {
        required
      }
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },

  data() {
    return {
      form: {
        domain: ''
      },
      isLoading: false,
      errors: null
    }
  },

  methods: {
    open() {
      this.$refs.modal.open()
      this.logEvent('start_domain_change')
    },

    resetForm() {
      this.form.domain = ''
      this.$v.$reset()
    },
    async changeDomain() {
      this.isLoading = true
      this.error = null
      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        await new WidgetService(this).updateWidgetDomain({
          id: this.id,
          domain: this.form.domain
        })
        this.fetchAllWidgets()
        this.$refs.modal.close()

        this.$store.commit('toast/update', { message: 'Domain updated!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Domain updated!', type: 'success', status: false })
        this.logEvent('complete_domain_change')
      } catch (error) {
        this.errors = error.response.data.errors
        this.isLoading = false
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.sweet-modal {
  overflow: hidden;
}
</style>
