export default class DashboardService {
  constructor(args) {
    this.axios = args.$axios
  }
  getDashboardData(startDate = null, endDate = null) {
    if (!startDate && !endDate) {
      return this.axios.get(`dashboard`)
    } else {
      return this.axios.get(`dashboard?filter[dates][between][start_date]=${startDate}&filter[dates][between][end_date]=${endDate}`)
    }
  }

  getSquareShop() {
    return this.axios.get(`square/fetch-user-sites`)
  }

  getShopifyEmbedStatus({ shop }) {
    return this.axios.get(`shopify/app-embedded-blocks?shop=${shop}`)
  }

  getShopifyEmbedDeepLink({ shop }) {
    return this.axios.get(`shopify/app-embedded-deeplink?shop=${shop}`)
  }

  connectSquareSite(site_id) {
    return this.axios.post(`square/scripts`, {
      site_id
    })
  }

  connectSquareStore(site_id) {
    return this.axios.post(`square/install-script`, {
      site_id
    })
  }

  disconnectSquareShop(site_id) {
    return this.axios.delete(`square/scripts`, {
      data: {
        site_id
      }
    })
  }

  logEvent({ event_src, event, url }) {
    return this.axios.post(`event/log`, {
      event_src,
      event,
      url
    })
  }
}
