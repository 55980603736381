export default class PlanService {
  constructor(args) {
    this.axios = args.$axios
  }
  getPlans() {
    return this.axios.get(`plans`)
  }

  wixShopifyCheckout({ payment_frequency, plan_id, country_code }) {
    return this.axios.post(`subscriptions`, {
      payment_frequency,
      plan_id,
      ...(country_code && { country_code })
    })
  }
  updateSubscription({ card_token, payment_frequency, plan_id, country_code, vat, company_name, reason }) {
    return this.axios.post(`subscriptions`, {
      payment_frequency,
      plan_id,
      ...(card_token && { card_token }),
      ...(country_code && { country_code }),
      ...(vat && { vat }),
      ...(company_name && { company_name }),
      ...(reason && { reason })
    })
  }

  updateShopifyStore({ shop, charge_id }) {
    return this.axios.post(`webhook/shopify/${shop}/billing`, {
      charge_id
    })
  }

  removeCard() {
    return this.axios.post(`subscriptions`, {
      payment_frequency: 'month',
      plan_id: 1,
      remove_card: 1
    })
  }

  addCard(card_token) {
    return this.axios.post(`cards`, {
      card_token
    })
  }

  getPayments() {
    return this.axios.get(`payments`)
  }

  getPaymentUrl({ duda_plan_id }) {
    return this.axios.post(`duda/upgradeUrl`, {
      ...(duda_plan_id && { duda_plan_id })
    })
  }

  revokeSquareAccess() {
    return this.axios.post(`square/revoke-access`)
  }

  cancelSquareSubscription({ reason }) {
    return this.axios.post(`square/cancel-subscription`, {
      ...(reason && { reason })
    })
  }
}
