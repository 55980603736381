<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    sessionStorage.setItem('ch_reset_token', this.$route.params.token)
    sessionStorage.setItem('ch_reset_email', this.$route.query.email)
    this.$router.push('/password/reset')
  }
}
</script>
