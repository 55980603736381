<template>
  <tbody>
    <tr>
      <td class="status no-br dark" rowspan="3">
        <span class="draft" v-if="widget.state == 'draft'">Draft</span>
        <span class="chaty-switch" v-if="widget.state == 'active'">
          <input type="checkbox" @change="changePublishStatus" :id="widget.identifier + 'switcher'" :name="widget.identifier + 'switcher'" v-model="published" />
          <label :for="widget.identifier + 'switcher'"></label>
        </span>
      </td>
      <td class="dark name" rowspan="3">
        <div>
          <div v-if="!isEditing" @dblclick="setEditMode" title="Double click to Edit">
            {{ widgetName }}
          </div>
          <div v-else>
            <form @submit.prevent="renameWidget"><input :disabled="isLoading" @blur="isEditing = false" type="text" ref="widgetName" class="widget--name" v-model="widgetName" /></form>
          </div>
        </div>
        <WidgetChannels :channels="widget.channel_names" />
      </td>
      <td class="device no-bb">
        <div class="has-svg">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.667 15c.916 0 1.666-.75 1.666-1.667V5c0-.917-.75-1.667-1.666-1.667H3.333c-.916 0-1.666.75-1.666 1.667v8.333c0 .917.75 1.667 1.666 1.667h-2.5a.836.836 0 00-.833.833c0 .459.375.834.833.834h18.334a.836.836 0 00.833-.834.836.836 0 00-.833-.833h-2.5zM4.167 5h11.666c.459 0 .834.375.834.833V12.5a.836.836 0 01-.834.833H4.167a.836.836 0 01-.834-.833V5.833c0-.458.375-.833.834-.833z" fill="#83A1B7"/></svg>
        </div>
      </td>
      <!-- Desktop Visitors -->
      <td class="no-bb">
        <!-- <span v-if="widget.desktop_visit_count != null">
          {{ widget.desktop_visit_count }}
        </span>
        <span v-else class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span> -->
        <span>
          {{ widget.desktop_visit_count ? widget.desktop_visit_count : 0 }}
        </span>
      </td>
      <!-- Desktop Unique Clicks	 -->
      <td class="no-bb">
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>
          {{ widget.desktop_click_count ? widget.desktop_click_count : 0 }}
        </span>
      </td>
      <!-- Desktop Click Rate -->
      <td class="no-bb">
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>{{ widget.desktop_click_rate ? parseFloat(widget.desktop_click_rate).toFixed(2) : 0 }}%</span>
      </td>
      <td class="action-col dark" rowspan="3">
        <WidgetActions :domain="domain" :widget="widget" />
      </td>
    </tr>
    <tr>
      <td class="device no-bt">
        <div class="has-svg">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.916.833H6.25c-1.15 0-2.083.934-2.083 2.084v14.166c0 1.15.933 2.084 2.083 2.084h6.666c1.15 0 2.084-.934 2.084-2.084V2.917c0-1.15-.934-2.084-2.084-2.084zm-3.333 17.5c-.691 0-1.25-.558-1.25-1.25 0-.691.559-1.25 1.25-1.25.692 0 1.25.559 1.25 1.25 0 .692-.558 1.25-1.25 1.25zM13.333 15h-7.5V3.333h7.5V15z" fill="#83A1B7"/></svg>
        </div>
      </td>
      <!-- Tablet Visitors -->
      <td class="no-br no-bt">
        <!-- <span v-if="widget.mobile_visit_count != null">
          {{ widget.mobile_visit_count }}
        </span>
        <span v-else class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span> -->
        <span>
          {{ widget.mobile_visit_count ? widget.mobile_visit_count : 0 }}
        </span>
      </td>
      <!-- Tablet Unique Clicks -->
      <td class="no-br no-bt">
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>
          {{ widget.mobile_click_count ? widget.mobile_click_count : 0 }}
        </span>
      </td>
      <!-- Tablet Click Rate -->
      <td class="no-bt">
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>{{ widget.mobile_click_rate ? parseFloat(widget.mobile_click_rate).toFixed(2) : 0 }}%</span>
      </td>
    </tr>
    <tr class="dark">
      <td class="device">
        <div class="has-svg">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 7c0-.55.45-1 1-1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-1.1 0-2 .9-2 2v11h-.5c-.83 0-1.5.67-1.5 1.5S.67 20 1.5 20H14v-3H4V7zm19 1h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z" fill="#83A1B7" opacity=".6"/></svg>
        </div>
      </td>
      <!-- Total Visitors -->
      <td>
        <!-- <span v-if="widget.total_views != null">
          {{ widget.total_views }}
        </span>
        <span v-else class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span> -->
        <span>
          {{ widget.total_views ? widget.total_views : 0 }}
        </span>
      </td>
      <!-- Total Unique Clicks  -->
      <td>
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>
          {{ widget.total_clicks ? widget.total_clicks : 0 }}
        </span>
      </td>
      <!-- Total Click Rate  -->
      <td>
        <span v-if="widget.is_default_open" class="unavailable has--tooltip">
          unavailable
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
          <div class="tooltip">{{ $t('widgets.unavailable') }}</div>
        </span>
        <span v-else>{{ widget.total_click_rate ? parseFloat(widget.total_click_rate).toFixed(2) : 0 }}%</span>
      </td>
    </tr>
  </tbody>
</template>

<script>
import WidgetActions from '@/components/widgets/WidgetActions.vue'
import WidgetChannels from '@/components/widgets/WidgetChannels.vue'
import { WidgetService } from '@/services/api/index'

export default {
  name: 'WidgetComponent',
  components: {
    WidgetActions,
    WidgetChannels
  },
  watch: {
    'widget.name'(value) {
      if (value) {
        this.widgetName = value
      }
    }
  },
  props: {
    widget: {
      type: Object
    },
    domain: {
      type: String
    }
  },
  data() {
    return {
      widgetName: '',
      isLoading: false,
      isEditing: false,
      published: null,
      favicon: null
    }
  },
  methods: {
    async changePublishStatus() {
      const published = this.published ? 1 : 0
      if (this.published) {
        this.logEvent('turn_on_widget')
      } else {
        this.logEvent('turn_off_widget')
      }
      this.isLoading = true
      try {
        await new WidgetService(this).changePublishStatus(this.widget.identifier, published)
        this.isLoading = false
        this.$store.commit('toast/update', { message: this.published ? 'Widget published' : 'Widget Unpublished!', type: 'success', status: true })
        await this.sleep(1000)
        this.$store.commit('toast/update', { message: this.published ? 'Widget published' : 'Widget Unpublished!', type: 'success', status: false })
        this.fetchAllWidgets()
      } catch (error) {
        this.$store.commit('toast/update', { message: 'An Error occurred', type: 'error', status: true })
        await this.sleep(1000)
        this.$store.commit('toast/update', { message: 'An Error occurred', type: 'error', status: false })
      }

      this.isLoading = false
    },
    async renameWidget() {
      this.isLoading = true
      try {
        const name = this.widgetName
        await new WidgetService(this).renameWidget({ id: this.widget.identifier, name })
        this.isLoading = false
        this.$store.commit('toast/update', { message: 'Rename successful', type: 'success', status: true })
        this.fetchAllWidgets()
        await this.sleep(1000)
        this.$store.commit('toast/update', { message: 'Rename successful', type: 'success', status: false })
        this.isEditing = false
      } catch (error) {
        this.$store.commit('toast/update', { message: 'An Error occurred', type: 'error', status: true })
        await this.sleep(1000)
        this.$store.commit('toast/update', { message: 'An Error occurred', type: 'error', status: false })
        this.isEditing = false
      }

      this.isLoading = false
    },
    setEditMode() {
      this.isEditing = true
      setTimeout(() => {
        const input = this.$refs.widgetName
        input.focus()
        input.select()
      }, 50)
    },
    getFormattedValue(val) {
      return parseFloat(val).toFixed(2)
    }
  },
  created() {
    this.published = this.widget.published || 0
    this.widgetName = this.widget.name
  }
}
</script>

<style></style>
