export default {
  namespaced: true,

  state: () => ({
    isLoggedIn: false
  }),

  mutations: {
    updateAuth(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    }
  },

  getters: {
    getAuth(state) {
      return state.isLoggedIn
    }
  }
}
