<template>
  <div></div>
</template>
<script>
import Cookies from 'js-cookie'

export default {
  mounted() {
    const auth = Cookies.get('ch_token')
    if (auth) {
      return this.$router.push({
        path: '/home'
      })
    }

    return this.$router.push({
      path: '/login'
    })
  }
}
</script>
