<template>
  <sweet-modal overlay-theme="dark" :blocking="isLoading" class="ch-modal" ref="modal" @close="$emit('close')">
    <div class="sweet-content__header" :class="{ 'is-validating': isLoading }">
      <h5 class="header ch-header">{{ $t('delete_widget.title') }}</h5>
    </div>

    <div class="sweet-content__content">
      <div class="modal-details">
        <div class="input-field__wrapper has--password-icon">
          <label>
            {{ $t('delete_widget.you_sure_you_want_to_delete', { widget_name: name }) }}
          </label>
          <label></label>
        </div>
      </div>
    </div>

    <div class="sweet-content__footer button--right">
      <div class="grid end-xs middle-xs">
        <input type="hidden" v-model="id" />
        <button type="button" @click="$refs.modal.close()" :disabled="isLoading" class="button--outline button--secondary">{{ $t('buttons.cancel') }}</button>
        <button type="button" :disabled="isLoading" class="button--outline danger" @click="deleteWidget">{{ $t('buttons.delete_widget') }}</button>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import { WidgetService } from '@/services/api/index'
export default {
  name: 'DeleteWidgetModal',
  props: {
    name: {
      type: String
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    open() {
      this.$refs.modal.open()
      this.logEvent('start_delete')
    },
    async deleteWidget() {
      this.isLoading = true
      try {
        await new WidgetService(this).deleteWidget(this.id)

        this.isLoading = false
        this.$refs.modal.close()
        await this.fetchAllWidgets()
        this.$store.commit('toast/update', { message: 'Widget Deleted!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Widget Deleted!', type: 'success', status: false })
        this.logEvent('complete_delete')
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.errors
        }
        this.isLoading = false
      }

      this.isLoading = false
    }
  }
}
</script>

<style></style>
