import Vue from 'vue'
import VueRouter from 'vue-router'
// Importing Cookies
import Cookies from 'js-cookie'

// Importing Index View
import IndexViewComponent from '@/views/index.vue'

// Layouts
import AuthLayout from '@/layouts/auth.vue'
const DashboardLayout = () => import(/* webpackChunkName: "dashboard" */ '@/layouts/dashboard.vue')
const UpgradeLayout = () => import(/* webpackChunkName: "upgrade-layout" */ '@/layouts/upgrade.vue')
const WidgetCreateLayout = () => import(/* webpackChunkName: "widget-create-layout" */ '@/layouts/widget.vue')
const DefaultLayout = () => import(/* webpackChunkName: "default-layout" */ '@/layouts/default.vue')

// Auth Components
import LoginComponent from '@/views/auth/login.vue'
import RegisterComponent from '@/views/auth/register.vue'
import ForgotPasswordComponent from '@/views/auth/forgot-password.vue'
import ResetPasswordTokenComponent from '@/views/auth/reset-password-token.vue'
import ResetPasswordComponent from '@/views/auth/reset-password.vue'

// Dashboard Components
const HomeViewComponent = () => import(/* webpackChunkName: "home"*/ '@/views/dashboard/home.vue')
const ProfileViewComponent = () => import(/* webpackChunkName: "profile"*/ '@/views/dashboard/profile.vue')

// Interceptors
import wixInterceptor from '@/router/interceptors/wix.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: IndexViewComponent
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      { path: 'login', name: 'login', component: LoginComponent, meta: { guest: true } },
      { path: 'register', name: 'register', component: RegisterComponent, meta: { guest: true } },
      { path: 'forgot-password', name: 'forgot-password', component: ForgotPasswordComponent, meta: { guest: true } },
      { path: 'password/reset/:token', name: 'password-reset-token', component: ResetPasswordTokenComponent, meta: { guest: true } },
      { path: 'password/reset/', name: 'password-reset', component: ResetPasswordComponent, meta: { guest: true } },

      // Load on demand
      { path: 'embed/:identifier', name: 'installation-code-copy', component: () => import(/* webpackChunkName: "installation-code-copy"*/ '@/views/others/embed-code.vue') },
      { path: 'embed_bigcartel/:identifier', name: 'installation-code-copy-bigcartel', component: () => import(/* webpackChunkName: "installation-code-copy-bigcartel"*/ '@/views/others/embed-code.vue') },
      { path: 'bigcartel', name: 'big-cartel-install', component: () => import(/* webpackChunkName: "big-cartel-install"*/ '@/views/auth/bigcartel-install.vue') },
      { path: 'big-cartel/oauth/overview', name: 'big-cartel-signup', component: () => import(/* webpackChunkName: "big-cartel-signup"*/ '@/views/auth/bigcartel-signup.vue') },
      { path: 'gain-access', name: 'gain-access-token', component: () => import(/* webpackChunkName: "gain-access-token"*/ '@/views/auth/gain-access-token.vue') },
      { path: 'user/gain-access', name: 'gain-access-user', component: () => import(/* webpackChunkName: "gain-access-user"*/ '@/views/auth/gain-access.vue'), meta: { guest: true } },
      { path: 'wix/oauth/overview', name: 'wix-login-token', component: () => import(/* webpackChunkName: "wix-login"*/ '@/views/auth/wix-login-token.vue') },
      { path: 'wix/login', name: 'wix-login', component: () => import(/* webpackChunkName: "wix-login"*/ '@/views/auth/wix-login.vue'), meta: { guest: true } },
      { path: 'install', name: 'thinkific-install', component: () => import(/* webpackChunkName: "thinkific-install"*/ '@/views/auth/thinkific-install.vue') },
      { path: 'thinkific/login', name: 'thinkific-login', component: () => import(/* webpackChunkName: "thinkific-login"*/ '@/views/auth/thinkific-login.vue') },
      { path: 'thinkific/oauth/overview', name: 'thinkific-signup', component: () => import(/* webpackChunkName: "thinkific-signup"*/ '@/views/auth/thinkific-signup.vue') },
      { path: 'nuvemshop/oauth/overview', name: 'nuvemshop-login-token', component: () => import(/* webpackChunkName: "nuvemshop-login-token"*/ '@/views/auth/nuvemshop-signup.vue') },
      { path: 'ecwid/oauth/overview', name: 'ecwid-login-token', component: () => import(/* webpackChunkName: "ecwid-login-token"*/ '@/views/auth/ecwid-signup.vue') },
      /**
       * Inside of prestashop module we show chaty dashboard through iframe
       * We receive user login token from prestashop and we check if user is valid
       * @route /prestashop
       * @example https://go.chaty.app/prestashop?token=something
       */
      { path: 'prestashop', name: 'prestashop-login', component: () => import(/* webpackChunkName: "prestashop-login"*/ '@/views/auth/prestashop-login.vue') },
      { path: 'shopify', name: 'shopify-install', component: () => import(/* webpackChunkName: "shopify-install"*/ '@/views/auth/shopify-install.vue') },
      { path: 'shopify/billing/:shop', name: 'shopify-billing', component: () => import(/* webpackChunkName: "shopify-billing"*/ '@/views/auth/shopify-billing.vue') },
      { path: 'shopify/oauth/overview', name: 'shopify-login', component: () => import(/* webpackChunkName: "shopify-login"*/ '@/views/auth/shopify-login.vue') },
      { path: 'duda/oauth/overview', name: 'duda-login', component: () => import(/* webpackChunkName: "duda-login"*/ '@/views/auth/duda-login.vue') },
      { path: 'square/oauth/overview', name: 'square-oauth-login', component: () => import(/* webpackChunkName: "square-oauth-login"*/ '@/views/auth/square-oauth-login.vue') },
      // { path: 'square/oauth/overview', name: 'square-oauth-login', component: () => import('@/views/auth/square-oauth-login.vue'), meta: { requiresAuth: true } },
      { path: 'connect/square', name: 'square-install', component: () => import('@/views/auth/square-install.vue') },
      // { path: 'dashboard/square', name: 'square-dashboard', component: () => import('@/views/auth/square-install.vue') },
      { path: 'dashboard/square', name: 'square-dashboard', component: () => import('@/views/auth/square-dashboard.vue') },
      { path: 'big-commerce/oauth/install', name: 'big-commerce-signup', component: () => import('@/views/auth/bigcommerce-install.vue') },
      { path: 'big-commerce/oauth/overview', name: 'big-commerce-login', component: () => import('@/views/auth/bigcommerce-login.vue') },
      { path: 'webflow', name: 'webflow-signup', component: () => import('@/views/auth/webflow-install.vue') },
      { path: 'webflow/oauth/install', name: 'webflow-signup-oauth', component: () => import('@/views/auth/webflow-install.vue') },
      { path: 'webflow/oauth/overview', name: 'webflow-login', component: () => import('@/views/auth/webflow-login.vue') },
      { path: 'shoptet-cz/oauth/install', name: 'shoptet-signup-cz', component: () => import('@/views/auth/shoptet-install.vue') },
      { path: 'shoptet-cz/oauth/overview', name: 'shoptet-login-cz', component: () => import('@/views/auth/shoptet-login.vue') },
      { path: 'shoptet-sk/oauth/install', name: 'shoptet-signup-sk', component: () => import('@/views/auth/shoptet-install.vue') },
      { path: 'shoptet-sk/oauth/overview', name: 'shoptet-login-sk', component: () => import('@/views/auth/shoptet-login.vue') },
      { path: 'shoptet-installation', name: 'shoptet-login', component: () => import('@/views/auth/shoptet-login.vue') },
      { path: 'shoptet-hu/oauth/install', name: 'shoptet-signup-hu', component: () => import('@/views/auth/shoptet-install.vue') },
      { path: 'shoptet-hu/oauth/overview', name: 'shoptet-login-hu', component: () => import('@/views/auth/shoptet-login.vue') },
      { path: 'verify-email/:id/:token', name: 'verify-email', component: () => import(/* webpackChunkName: "prestashop-login"*/ '@/views/auth/verify-email.vue') },
      { path: 'google/oauth/install', name: 'google-login', component: () => import(/* webpackChunkName: "google-login"*/ '@/views/auth/google-login.vue') }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      { path: 'home', name: 'home', component: HomeViewComponent, meta: { requiresAuth: true, isWix: true } },
      // Lazy loading the widget view
      { path: 'widgets', name: 'widgets', component: () => import(/* webpackChunkName: "widgets"*/ '@/views/dashboard/widgets.vue'), meta: { requiresAuth: true } },
      { path: 'profile', name: 'profile', component: ProfileViewComponent, meta: { requiresAuth: true } },
      // Lazy loading the plan view
      { path: 'plans', name: 'plans', component: () => import(/* webpackChunkName: "plans"*/ '@/views/dashboard/plans.vue'), meta: { requiresAuth: true, isWix: true } },
      { path: 'plans/update', name: 'plan-update', component: () => import(/* webpackChunkName: "plan-update"*/ '@/views/dashboard/plans.vue'), meta: { requiresAuth: true } },
      // Lazy loading the billing view
      { path: 'billing', name: 'billing', component: () => import(/* webpackChunkName: "billing"*/ '@/views/dashboard/billing.vue'), meta: { requiresAuth: true } },
      // Lazy loading the leads view
      { path: 'leads', name: 'leads', component: () => import(/* webpackChunkName: "leads"*/ '@/views/dashboard/leads.vue'), meta: { requiresAuth: true } },

      { path: 'square-shops', name: 'square-shop-list', component: () => import(/* webpackChunkName: "square-shop-list"*/ '@/views/dashboard/square-stores.vue'), meta: { requiresAuth: true } },
      /**
       * Display recommended products for the specific marketplace
       */
      { path: 'products/:marketplace', name: 'products', component: () => import(/* webpackChunkName: "products"*/ '@/views/dashboard/products.vue'), meta: { requiresAuth: true } }
    ]
  },
  {
    path: '/',
    component: WidgetCreateLayout,
    children: [
      { path: 'update-widget/:id', name: 'update-widget', component: () => import(/* webpackChunkName: "update-widget"*/ '@/views/dashboard/update-widget.vue'), props: true, meta: { requiresAuth: true } }
    ]
  },
  {
    path: '/',
    component: UpgradeLayout,
    children: [{ path: 'upgrade', name: 'upgrade-plan', component: () => import(/* webpackChunkName: "upgrade"*/ '@/views/dashboard/upgrade.vue'), props: true, meta: { requiresAuth: true } }]
  },
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: 'shopify-app-embed',
        name: 'shopify-app-embed',
        component: () => import(/* webpackChunkName: "shopify-app-embed"*/ '@/views/dashboard/shopify-app-embed/index.vue'),
        props: true,
        meta: { requiresAuth: true }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
})

// Meta Handling
router.beforeEach((to, from, next) => {
  if ('shopify-install' === to.name) {
    const token = localStorage.getItem('ch_token')
    const isVerifying = to.query['verify-email']
    const code = to.query.code
    const state = to.query.state
    const shop = to.query.shop
    localStorage.setItem('sh_shop', shop)

    if (token && !isVerifying && !code && !state) {
      return next({ name: 'home' })
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = localStorage.getItem('ch_token')

    // const token = Cookies.get('ch_token') || localStorage.getItem('ch_token')
    if (!token) {
      let interceptors = false
      if (to.matched.some(record => record.meta.isWix)) {
        interceptors = wixInterceptor(to)
      } else {
        interceptors = true
      }
      if (!interceptors) {
        return false
      }
      Cookies.set('ch_last_visit', (location.pathname + location.search).substr(1))
      window.location = '/login'
    } else {
      if (to.matched.some(record => record.meta.isWix)) {
        wixInterceptor(to)
      }
      next()
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    let token = localStorage.getItem('ch_token')

    if (!token) {
      // if (!Cookies.get('ch_token')) {
      next()
    } else {
      next({ name: 'home' })
    }
  } else {
    next()
  }
})

export default router
