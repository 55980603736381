<template>
  <div class="form-area" :class="{ 'is-validating': isLoading }">
    <div class="header">
      <img width="130" height="56" loading="lazy" src="images/svg/logo.svg" alt="site logo" />
    </div>
    <div class="main-text">
      <h3>Set new password</h3>
    </div>
    <div class="main-form">
      <form @submit.prevent="resetPassword">
        <div class="input-field__wrapper">
          <label for="new_password">New Password</label>
          <PasswordEyeButton :type="passwordType" @update:type="e => (passwordType = e)">
            <input :type="passwordType" id="new_password" name="new_password" v-model="form.newPassword" @blur="$v.form.newPassword.$touch()" :class="{ error: $v.form.newPassword.$error }" />
          </PasswordEyeButton>
          <div v-if="$v.form.newPassword.$dirty">
            <span class="input-field__message error" v-if="!$v.form.newPassword.required">New password is required.</span>
            <span class="input-field__message error" v-if="!$v.form.newPassword.minLength">New password must have a minimum of 6 characters.</span>
          </div>
        </div>

        <div class="input-field__wrapper">
          <label for="verify_password">Verify Password</label>
          <PasswordEyeButton :type="verifyPasswordType" @update:type="e => (verifyPasswordType = e)">
            <input
              :type="verifyPasswordType"
              id="verify_password"
              name="verify_password"
              v-model="form.verifyPassword"
              @blur="$v.form.verifyPassword.$touch()"
              :class="{ error: $v.form.verifyPassword.$error }"
            />
          </PasswordEyeButton>
          <div v-if="$v.form.verifyPassword.$dirty">
            <span class="input-field__message error" v-if="!$v.form.verifyPassword.required">Verify password is required.</span>
            <span class="input-field__message error" v-if="!$v.form.verifyPassword.sameAsPassword">Password does not match</span>
          </div>
          <div v-if="errors">
            <div v-for="(error, key) in errors" :key="key">
              <span class="input-field__message error" v-for="message in error" :key="message">
                {{ message }}
              </span>
            </div>
          </div>
        </div>
        <button class="button--full button--darker" :disabled="disabled">
          Update
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 9h14M9 2l7 7-7 7" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </form>
    </div>
    <div class="secondary-actions">
      <div class="m-t-10">
        <router-link to="/register" class="has--hover">Create a free account</router-link>
      </div>
      <div class="m-t-10">
        <router-link to="/forgot-password" class="has--hover">Forgot password?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthService } from '@/services/api/index'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import PasswordEyeButton from '@/components/global/PasswordEyeButton.vue'

export default {
  metaInfo: {
    title: 'Reset Password | Chaty'
  },
  components: {
    PasswordEyeButton
  },
  validations: {
    form: {
      newPassword: {
        required,
        minLength: minLength(6)
      },
      verifyPassword: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    }
  },
  data() {
    return {
      form: {
        newPassword: '',
        verifyPassword: ''
      },
      isLoading: false,
      errors: null,
      passwordType: 'password',
      verifyPasswordType: 'password'
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },
  methods: {
    async resetPassword() {
      this.isLoading = true
      this.errors = null

      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        const token = sessionStorage.getItem('ch_reset_token')
        const email = sessionStorage.getItem('ch_reset_email') || ''
        await new AuthService(this).resetPassword({ token, email, password: this.form.newPassword, password_confirmation: this.form.verifyPassword })

        sessionStorage.removeItem('ch_reset_token')
        sessionStorage.removeItem('ch_reset_email')

        this.$router.push('/login')
      } catch (error) {
        this.errors = error.response.data.errors ? error.response.data.errors : error.response.data
        this.isLoading = false
      }

      this.isLoading = false
    }
  },
  created() {
    const token = sessionStorage.getItem('ch_reset_token')
    if (!token) this.$router.push('login')
  }
}
</script>

<style></style>
