export default {
  namespaced: true,
  state: () => ({
    all: [
      {
        title: 'Chatway',
        rating: '5.0',
        stars: 5,
        description: 'Live chat customer support app for Shopify. iOS and Android apps are available too',
        install: 'https://apps.shopify.com/chatway/?utm_source=chatyapp',
        logo: require('@/assets/images/logo/product-chatway.webp'),
        learnMore: 'https://chatway.app',
        marketplace: 'shopify'
      },
      {
        title: 'Poptin',
        rating: '4.9',
        stars: 5,
        description: 'Get more sales with exit intent pop ups, forms, spin-the-wheel pop-ups, and more',
        install: 'https://apps.shopify.com/poptin/install',
        logo: require('@/assets/images/logo/product-poptin.webp'),
        learnMore: 'https://www.poptin.com',
        marketplace: 'shopify'
      },
      {
        title: 'Coupon X',
        rating: '5.0',
        stars: 5,
        description: 'Discount code pop ups. You can create unique or general coupons for your visitors',
        install: 'https://apps.shopify.com/coupon-x-discount-codes/install',
        logo: require('@/assets/images/logo/product-coupon-x.webp'),
        learnMore: 'https://apps.shopify.com/coupon-x-discount-codes',
        marketplace: 'shopify'
      }
    ]
  }),

  getters: {
    getAllProducts(state) {
      return state.all
    }
  }
}
