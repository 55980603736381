import axios from 'axios'
export default class UtilityService {
  constructor(args) {
    this.axios = args.$axios
  }
  uploadImage(image) {
    const formData = new FormData()
    formData.append('file', image, image.name)
    return this.axios.post(`upload/photo`, formData)
  }
  fetchFont(font) {
    return this.axios.get(`https://fonts.googleapis.com/css?family=${font}':400`, { baseURL: '/' })
  }

  getFavicon(url) {
    // A new instance of axios was used so as to avoid sending jwt with the request
    return axios.get(`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.${url}&size=100`, { baseURL: '/' })
  }

  mailCode(email) {
    return this.axios.post(`mail/embed-code`, {
      email
    })
  }

  /**
   * Republish site
   * @param {'duda' | 'webflow'} platform - The platform to republish the site on
   */
  republishSite(platform = 'duda', payload) {
    return this.axios.post(`${platform}/republish-site`, payload)
  }

  getLocation() {
    return axios.get('https://www.cloudflare.com/cdn-cgi/trace')
  }
}
