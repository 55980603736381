<template>
  <sweet-modal overlay-theme="dark" class="ch-modal" ref="modal" @close="$emit('close')">
    <div class="sweet-content__header" :class="{ 'is-validating': isLoading }">
      <h5 class="header ch-header">{{ $t('duplicate_widget.title') }}</h5>
    </div>
    <form action="" @submit.prevent="duplicateWidget">
      <div class="sweet-content__content">
        <div class="modal-details">
          <div class="input-field__wrapper">
            <label for="widget_name">{{ $t('duplicate_widget.select_name_to_duplicate_widget') }}</label>
            <input type="text" id="domain" :dir="dir(form.name)" name="domain" v-model="form.name" @blur="$v.form.name.$touch()" :class="{ error: $v.form.name.$error }" />
            <div v-if="$v.form.name.$dirty">
              <span class="input-field__message error" v-if="!$v.form.name.required">{{ $t('validation.required.widget_name') }}</span>
            </div>
            <div v-if="errors && errors.name">
              <span class="input-field__message error" v-for="error in errors.name" :key="error">
                {{ error }}
              </span>
            </div>
          </div>
          <div class="input-field__wrapper">
            <label for="widget_name">{{ $t('change_domain.enter_your_desired_domain') }}</label>
            <input type="text" id="domain" name="domain" v-model="form.domain" @blur="$v.form.domain.$touch()" :class="{ error: $v.form.domain.$error }" />
            <div v-if="$v.form.domain.$dirty">
              <span class="input-field__message error" v-if="!$v.form.domain.required">{{ $t('validation.required.widget_domain') }}</span>
            </div>
            <div v-if="errors && errors.domain">
              <span class="input-field__message error" v-for="error in errors.domain" :key="error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="sweet-content__footer button--right">
        <div class="grid end-xs middle-xs">
          <input type="hidden" v-model="id" />
          <button type="button" @click="$refs.modal.close()" :disabled="isLoading" class="button--outline button--secondary">{{ $t('buttons.cancel') }}</button>
          <button type="submit" :disabled="disabled" class="button--outline">{{ $t('buttons.duplicate_widget') }}</button>
        </div>
      </div>
    </form>
  </sweet-modal>
</template>

<script>
import { WidgetService } from '@/services/api/index'
import { required, helpers } from 'vuelidate/lib/validators'
import UtilsMixin from '@/plugins/mixins/utils'
const domainCheck = helpers.regex('domainCheck', /((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|localhost)/i)

export default {
  name: 'DuplicateWidgetModal',
  props: ['id', 'domain'],
  mixins: [UtilsMixin],
  validations: {
    form: {
      name: {
        required
      },
      domain: {
        required,
        domainCheck
      }
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },

  data() {
    return {
      form: {
        name: '',
        domain: ''
      },
      isLoading: false,
      errors: null
    }
  },

  methods: {
    open() {
      this.$refs.modal.open()
      this.logEvent('start_duplicate')
    },

    resetForm() {
      this.form.name = ''
      this.form.domain = ''
      this.$v.$reset()
    },
    async duplicateWidget() {
      this.isLoading = true
      this.error = null
      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        await new WidgetService(this).duplicateWidget({
          id: this.id,
          name: this.form.name,
          domain: this.form.domain
        })
        this.fetchAllWidgets()
        this.$refs.modal.close()

        this.$store.commit('toast/update', { message: 'Widget Duplicated', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Widget Duplicated', type: 'success', status: false })
        this.logEvent('complete_duplicate')
      } catch (error) {
        this.errors = error.response.data.errors
        this.isLoading = false
      }

      this.isLoading = false
    }
  },
  mounted() {
    this.form.domain = this.domain
  }
}
</script>

<style></style>
