<template>
  <sweet-modal overlay-theme="dark" class="ch-modal" ref="modal" @close="$emit('close')">
    <div class="sweet-content__header" :class="{ 'is-validating': isLoading }">
      <h5 class="header ch-header">{{ $t('modals.rename_widget.title') }}</h5>
    </div>
    <form action="" @submit.prevent="renameWidget">
      <div class="sweet-content__content" :class="{ 'is-validating': isLoading }">
        <div class="modal-details">
          <div class="input-field__wrapper has--password-icon">
            <label for="widget_name">{{ $t('modals.rename_widget.label') }}</label>
            <input type="text" id="domain" :dir="dir(form.name)" name="domain" v-model="form.name" @blur="$v.form.name.$touch()" :class="{ error: $v.form.name.$error }" />
            <div v-if="$v.form.name.$dirty">
              <span class="input-field__message error" v-if="!$v.form.name.required">{{ $t('validation.required.widget_name') }}</span>
            </div>
            <div v-if="errors && errors.name">
              <span class="input-field__message error" v-for="error in errors.name" :key="error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="sweet-content__footer button--right">
        <div class="grid end-xs middle-xs">
          <input type="hidden" v-model="id" />
          <button type="button" @click="$refs.modal.close()" :disabled="isLoading" class="button--outline button--secondary">{{ $t('buttons.cancel') }}</button>
          <button type="submit" :disabled="isLoading" class="button--outline">{{ $t('buttons.rename_widget') }}</button>
        </div>
      </div>
    </form>
  </sweet-modal>
</template>

<script>
import { WidgetService } from '@/services/api/index'
import { required } from 'vuelidate/lib/validators'
import UtilsMixin from '@/plugins/mixins/utils'

export default {
  name: 'RenameWidgetModal',
  props: ['id'],
  mixins: [UtilsMixin],
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },

  data() {
    return {
      form: {
        name: ''
      },
      isLoading: false,
      errors: null
    }
  },

  methods: {
    open() {
      this.$refs.modal.open()
      this.logEvent('start_rename')
    },

    close() {
      this.resetForm()
      this.$refs.modal.close()
    },

    resetForm() {
      this.form.name = ''
      this.$v.$reset()
    },
    async renameWidget() {
      this.isLoading = true
      this.error = null
      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        await new WidgetService(this).renameWidget({
          id: this.id,
          name: this.form.name
        })
        await this.fetchAllWidgets()
        this.close()
        this.isLoading = false

        this.$store.commit('toast/update', { message: 'Name updated!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Name updated!', type: 'success', status: false })
        this.logEvent('complete_rename')
      } catch (error) {
        this.errors = error.response.data.errors
        this.isLoading = false
      }

      this.isLoading = false
    }
  }
}
</script>

<style></style>
