<template functional>
  <div class="loading-placeholder">
    <div v-if="props.navbar" class="grid flex-column middle-xs center-xs no-gutters p-container">
      <div class="p-circle" style="width: 90px; height: 90px"></div>
      <div class="p-item p-item--3 m-t-10 w-50" style="margin: 0"></div>
      <div class="p-item p-item--2 m-t-10 w-60" style="margin: 0"></div>
    </div>

    <div v-if="props.widget_listing" class="grid no-gutters p-container">
      <h4 style="font-size: 2.5rem" class="m-b-40">Widgets</h4>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
    </div>

    <div v-if="props.square_listing" class="grid no-gutters p-container">
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
      <div class="c-xs-12 p-item p-item--8 m-b-10"></div>
    </div>

    <div v-if="props.leads" class="grid no-gutters p-container">
      <h4 style="font-size: 2.5rem" class="m-b-40">Contact Form Leads</h4>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
    </div>

    <div v-if="props.home" class="grid no-gutters p-container">
      <h4 style="font-size: 2.5rem" class="m-b-40">Dashboard</h4>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
    </div>

    <div v-if="props.text" class="grid p-container no-gutters">
      <div class="c-xs-12 p-item p-item--3" style="margin: 0"></div>
    </div>

    <div v-if="props.widget_update" class="grid no-gutters p-container">
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--25 m-b-20"></div>
    </div>

    <div v-if="props.login" class="grid no-gutters p-container">
      <div class="c-xs-12 p-item p-item--5 m-b-5"></div>
      <div class="c-xs-12 p-item p-item--5 m-b-20"></div>
      <div class="c-xs-12 p-item p-item--6 m-b-20"></div>
    </div>

    <div v-if="props.plans" class="grid p-container plan--listing no-gutters m-t-30">
      <div class="c-xs-12 c-md-3 p-item plan m-b-20"></div>
      <div class="c-xs-12 c-md-3 p-item plan m-b-20"></div>
      <div class="c-xs-12 c-md-3 p-item plan m-b-20"></div>
      <div class="c-xs-12 c-md-3 p-item plan m-b-20"></div>
      <div class="c-xs-12 p-item p-item--30"></div>
    </div>

    <div v-if="props.billing" class="grid p-container billing between-xs m-t-30">
      <div class="c-xs-12 c-sm-3 p-item side--bar"></div>
      <div class="c-xs-12 c-sm-8 p-item payment--listing"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['navbar', 'widget_listing', 'widget_update', 'home', 'plans', 'billing', 'login', 'leads', 'square_listing']
}
</script>
