<template>
  <div class="widget">
    <div class="widget-table">
      <div class="widget--header" v-if="domain.url">
        <span class="site-icon" v-if="faviconUrl">
          <img :src="faviconUrl" />
        </span>
        <span v-else class="site-icon empty d-flex align-items-center justify-content-center">
          <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="11" width="16" height="16" rx="8" fill="#C6D7E3"/><rect x=".5" y="5.5" width="13" height="13" rx="2.5" fill="#C6D7E3" stroke="#fff"/><path d="M19.24 9.937a1.5 1.5 0 00-2.48 0l-6.773 9.97c-.676.996.037 2.343 1.24 2.343h13.545c1.204 0 1.917-1.347 1.241-2.343l-6.772-9.97z" fill="#C6D7E3" stroke="#fff"/></svg>
        </span>
        <a :href="'https://' + getDomain(domain.url)" target="_blank">{{ domain.url }}</a>
      </div>
      <div class="responsive-table">
        <table class="table" cellpadding="0" cellspacing="0" :class="{ 'single--domain': domain.widget && domain.widget.length == 1 }">
          <thead>
            <tr>
              <th class="status"></th>
              <th class="name">{{ $t('widgets.name') }}</th>
              <th class="devices"></th>
              <th>{{ $t('widgets.visitors') }}</th>
              <th>{{ $t('widgets.unique_clicks') }}</th>
              <th>{{ $t('widgets.click_rate') }}</th>
              <th class="action-col"></th>
            </tr>
          </thead>
          <!-- <tbody> -->
          <WidgetComponent :widget="widget" :domain="domain.url" v-for="widget in domain.widget" :key="widget.id" />
          <tfoot v-if="domain.widget && domain.widget.length > 1">
            <tr>
              <td></td>
              <td class="name">Summary</td>
              <td class="device">
                <div class="has-svg">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 7c0-.55.45-1 1-1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-1.1 0-2 .9-2 2v11h-.5c-.83 0-1.5.67-1.5 1.5S.67 20 1.5 20H14v-3H4V7zm19 1h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z" fill="#83A1B7" opacity=".6"/></svg>
                </div>
              </td>
              <td>{{ domain.total_visits ? domain.total_visits : 0 }}</td>
              <td>{{ domain.total_clicks ? domain.total_clicks : 0 }}</td>
              <td>{{ domain.click_rate ? parseFloat(domain.click_rate).toFixed(2) : 0 }}</td>
              <td class="action-col"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetComponent from '@/components/widgets/WidgetComponent.vue'
import { UtilityService } from '@/services/api/index'

export default {
  name: 'DomainComponent',
  components: { WidgetComponent },
  props: {
    domain: {
      type: Object
    }
  },
  data() {
    return {
      favicon: null,
      hasFavicon: false
    }
  },
  computed: {
    faviconUrl() {
      return `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www.${this.getDomain(this.domain.url)}&size=100`

      // if (this.hasFavicon) {
      //   return `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://www${this.getDomain(this.domain.url)}&size=100`
      // }
      // return null
    }
  },
  methods: {
    async getFavicon() {
      try {
        await new UtilityService(this).getFavicon(this.getDomain(this.domain.url))
        this.hasFavicon = true
      } catch (error) {
        this.hasFavicon = false
      }
    }
  },
  created() {
    // if (process.env.NODE_ENV != 'development') {
    //   this.getFavicon()
    // }
  }
}
</script>

<style></style>
