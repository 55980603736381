<template>
  <div>
    <div class="home">
      <h4 class="page-header">{{ $t('home.title') }}</h4>
      <div class="cta grid middle-xs between-xs">
        <ChatySelectComponent :list="list" :hasIcon="true" />

        <button @click="$refs.createWidgetModal.open()" type="button" class="button button--has-svg-left text-500">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.833 2.5H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667zM10 6.667v6.666M6.667 10h6.666" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ $t('home.newWidget') }}
        </button>
      </div>
      <div class="page-card no--padding">
        <div class="page-card--body">
          <div class="grid middle-xs">
            <h5 class="salutation">{{ $t('home.salutations', { name: user ? `${user.first_name}` : '' }) }}</h5>
            <div class="home-info">
              <div class="info">
                <div class="label">{{ $t('home.nextResetData') }}</div>
                <div class="value">08/11/21</div>
              </div>
              <div class="info">
                <div class="label">
                  {{ $t('home.domainNumber') }}
                </div>
                <div class="value">1</div>
              </div>
              <div class="info">
                <div class="label">{{ $t('home.widgetCreated') }}</div>
                <div class="value">3/5</div>
              </div>
            </div>
          </div>
          <div class="home-cards">
            <div class="card">
              <div class="grid end-xs">
                <div class="card__tooltip has--tooltip">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  <div class="tooltip">Conversion rate is the number of people taled with your company</div>
                </div>
              </div>
              <div class="card__content">
                <div class="grid no-gutters middle-xs">
                  <div class="card__icon">
                    <img width="40" height="40" loading="lazy" alt="visitors" src="images/svg/icons/visitors.svg" />
                  </div>
                  <div class="card__info">
                    <div>
                      <span>{{ $t('home.visitors') }}</span>
                    </div>
                    <div>
                      <h5>
                        340
                        <sub>/ 500</sub>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="card__progress">
                  <div class="progress" style="width: 50%"></div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="grid end-xs">
                <div class="card__tooltip has--tooltip">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
              </div>
              <div class="card__content">
                <div class="grid no-gutters middle-xs">
                  <div class="card__icon">
                    <img width="40" height="40" loading="lazy" alt="unique-clicks" src="images/svg/icons/unique-clicks.svg" />
                  </div>
                  <div class="card__info">
                    <div>
                      <span>{{ $t('home.uniqueClicks') }}</span>
                    </div>
                    <div>
                      <h5>16</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="grid end-xs">
                <div class="card__tooltip has--tooltip">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
              </div>
              <div class="card__content">
                <div class="grid no-gutters middle-xs">
                  <div class="card__icon">
                    <img width="40" height="40" loading="lazy" alt="conversion-rate" src="images/svg/icons/conversation-rate.svg" />
                  </div>
                  <div class="card__info">
                    <div>
                      <span>{{ $t('home.conversationRate') }}</span>
                    </div>
                    <div>
                      <h5>7.24%</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-card no--padding graph">
        <div class="page-card--header">
          <div class="graph__actions grid no-gutters middle-xs start-xs">
            <div class="primary-action">
              <div>
                <input type="checkbox" name="confirmation" id="visitors" />
                <label for="visitors" class="checkbox secondary">{{ $t('home.visitors') }}</label>
              </div>
              <div>
                <input type="checkbox" name="confirmation" id="Unique_clicks" />
                <label for="Unique_clicks" class="checkbox secondary">{{ $t('home.uniqueClicks') }}</label>
              </div>
            </div>

            <div class="secondary-action">
              <div>
                <input type="checkbox" name="confirmation" id="Desktop" />
                <label for="Desktop" class="checkbox">{{ $t('home.desktop') }}</label>
              </div>
              <div>
                <input type="checkbox" name="confirmation" id="Mobile" />
                <label for="Mobile" class="checkbox">{{ $t('home.mobile') }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="page-card--body dashboard-svg">
          <svg width="984" height="265" viewBox="0 0 984 265" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 219l34.14-35.08 35.644-46.106 35.143-13.03 34.642 5.513 35.143-23.554 35.645 54.625 34.641-92.212 35.645-51.117L317.79 7.014h33.637l34.641 15.535 35.646-4.51L457.359 1 492 27.56l35.143 92.714 34.641 41.595 36.65-6.014 35.143-20.046 33.637-15.535 35.645 7.016 35.143-17.541 35.645-7.517h34.641l35.143 7.517 34.642-10.023 36.649-13.53 33.135-6.014H983" stroke="#4CB3FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path opacity=".08" d="M35.14 184.5L1 219.5V265h982V81h-35.143l-33.135 6-36.649 13.5-34.642 10-35.143-7.5h-34.641l-35.645 7.5-35.143 17.5-35.645-7-33.637 15.5-35.143 20-36.65 6-34.641-41.5L492 28.5 457.359 2l-35.645 17-35.646 4.5L351.427 8H317.79l-36.147 11-35.645 51-34.641 92-35.645-54.5-35.144 23.5-34.641-5.5-35.143 13-35.645 46z" fill="#4CB3FD"/></svg>
        </div>
      </div>
      <CreateWidgetModal ref="createWidgetModal" />
    </div>
    <ShopifyEmbedAppPlaceholder>
      <img src="@/assets/images/empty-state/home.png" alt="" />
      <p>
        {{ $t('widgets.no_widget') }}
      </p>
      <div class="buttons">
        <a @click.prevent="$refs.YouTubeModal.open()" href="#" class="button button--has-svg-left button--outline">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.167 2.5L15.833 10 4.167 17.5v-15z" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ $t('widgets.watch_tutorial') }}
        </a>
        <button @click="$refs.createWidgetModal.open()" type="button" class="button button--has-svg-left text-normal">{{ $t('widgets.create_widget') }}</button>
      </div>
    </ShopifyEmbedAppPlaceholder>
    <CreateWidgetModal ref="createWidgetModal" />
    <YouTubeModal ref="YouTubeModal" />
  </div>
</template>

<script>
import CreateWidgetModal from '@/components/modals/CreateWidget.vue'
import YouTubeModal from '@/components/modals/YouTubeModal.vue'
import ChatySelectComponent from '@/components/global/ChatySelect.vue'
import ShopifyEmbedAppPlaceholder from './ShopifyEmbedAppPlaceholder.vue'

export default {
  metaInfo: {
    title: 'Dashboard | Chaty'
  },

  components: {
    YouTubeModal,
    CreateWidgetModal,
    ChatySelectComponent,
    ShopifyEmbedAppPlaceholder
  },
  data() {
    return {
      list: [
        {
          name: 'Today',
          value: 'today'
        },
        {
          name: 'Yesterday',
          value: 'yesterday'
        },
        {
          name: 'This week',
          value: 'this_week'
        },
        {
          name: 'Last 7 days',
          value: '7_days'
        },
        {
          name: 'This month',
          value: 'this_month'
        },
        {
          name: 'Last 30 days',
          value: 'last_30_days'
        },
        {
          name: 'Last month',
          value: 'last_month'
        },
        {
          name: 'All time',
          value: 'all_time'
        },
        {
          name: 'Custom',
          value: 'custom'
        }
      ]
    }
  }
}
</script>

<style scoped>
.home {
  max-height: calc(100vh - 120px);
  overflow: hidden;
}
</style>
