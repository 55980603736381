import Vue from 'vue'
import Vuex from 'vuex'
import app from './app' //Importing App State
import leads from './leads' //Importing App State
import loader from './loader' //Importing App State
import plan from './plan' //Importing Plan State
import toast from './toast' //Importing Toast State
import user from './user' //Importing User State
import widget from './widget' //Importing Widget State
import products from './products' //Importing Products State

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    leads,
    loader,
    plan,
    toast,
    user,
    widget,
    products
  }
})
