import copy from 'copy-to-clipboard'
// Importing dayjs
import dayjs from 'dayjs'
import { DashboardService } from '@/services/api/index'

export default {
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    // Help copy text to clipboard
    async copy(text) {
      copy(text)
    },

    // Helper that help check if array/object is empty
    isEmpty(args) {
      if (typeof args === 'object') {
        if (args instanceof Array) {
          return !!args.length
        } else {
          for (let i in args) {
            return false
          }
          return true
        }
      }
      return args
    },

    // Get UUID
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
    },

    formatDate(date) {
      return dayjs(date).format('MM/DD/YYYY')
    },

    getDomain(domain) {
      if (domain.startsWith('https://')) return domain.replace('https://', '')
      if (domain.startsWith('http://')) return domain.replace('http://', '')
      if (domain.startsWith('www.')) return domain.replace('www.', '')
      return domain
    },

    isFramed() {
      return window.location !== window.parent.location
    },
    /**
     * function to check if the text contains RTL characters
     * @param {string} text
     * @returns {'ltr' | 'rtl'}
     */
    dir(text = '') {
      // Regular expression to match RTL characters
      const rtlRegex = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u0591-\u05F4\uFB1D-\uFB4F\u2000-\u206F\uFB50-\uFDFF\uFE70-\uFEFF\u202A-\u202E\u2066-\u2069\u0600-\u06FF]/
      return rtlRegex.test(text) ? 'rtl' : 'ltr'
    },
    /**
     * check shopify embed status
     * @param {Array<{
     *  attributes: {
     *    url: string
     *  }
     * }>} shops
     */
    getShopifyEmbedStatus(shops) {
      return new Promise(async (resolve, reject) => {
        /**
         * @const shop_url can be null
         */
        const shop_url = localStorage.getItem('sh_shop')

        try {
          const specificShop = shops.find(shop => shop.attributes.url === shop_url)

          // when shop url coming from local storage match with the shop url in the shops array
          if (specificShop) {
            const embedStatusResponse = await new DashboardService(this).getShopifyEmbedStatus({
              shop: specificShop.attributes.url
            })

            return resolve({
              ...embedStatusResponse.data.attributes,
              shop: specificShop.attributes.url
            })
          }

          const embedStatus = []
          for (const shop of shops) {
            const embedStatusResponse = await new DashboardService(this).getShopifyEmbedStatus({
              shop: shop.attributes.url
            })
            embedStatus.push({
              ...embedStatusResponse.data.attributes,
              shop: shop.attributes.url
            })
          }

          const findSingleStatus = embedStatus.find(status => {
            return !status.is_embedded_app_enabled && !status.is_script_injected
          })

          if (findSingleStatus) {
            resolve(findSingleStatus)
          } else {
            resolve(embedStatus[0])
          }
        } catch (error) {
          console.error(error)
          reject({
            is_embedded_app_enabled: true,
            is_app_embedded: false,
            is_script_injected: true,
            shop: shop_url
          })
        }
      })
    }
  }
}
