<template>
  <sweet-modal overlay-theme="dark" :blocking="isLoading" class="ch-modal widget-modal-wrapper" ref="modal" @close="resetForm">
    <div class="sweet-content__header" :class="{ 'is-validating': isLoading }">
      <h5 class="header ch-header">{{ $t('create_widget.new_widget') }}</h5>
    </div>
    <ImageCarouselFade v-if="totalWidgets === 0" :urls="carouselImages" />
    <form action="" @submit.prevent="createWidget">
      <div class="sweet-content__content">
        <div class="modal-details">
          <!-- Name Field -->
          <div class="input-field__wrapper">
            <label for="name">
              {{ $t('create_widget.name') }}
              <span class="label__tooltip has--tooltip">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="tooltip">{{ $t('create_widget.tooltip.name') }}</span>
              </span>
            </label>
            <input
              type="text"
              id="name"
              :dir="dir(form.name)"
              name="name"
              v-model="form.name"
              @blur="$v.form.name.$touch()"
              :class="{ error: $v.form.name.$error }"
              :placeholder="$t('create_widget.placeholder.name')"
            />
            <div v-if="$v.form.name.$dirty">
              <span class="input-field__message error" v-if="!$v.form.name.required">{{ $t('validation.required.widget_name') }}</span>
            </div>
            <div v-if="errors && errors.name">
              <span class="input-field__message error" v-for="error in errors.name" :key="error">
                {{ error }}
              </span>
            </div>
          </div>

          <!-- Domain Field -->
          <div class="input-field__wrapper">
            <label for="domain">
              {{ $t('create_widget.domain') }}
              <span class="label__tooltip has--tooltip">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666zM10 13.333V10M10 6.667h.008" stroke="currentColor" stroke-width="2.08" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <span class="tooltip">{{ $t('create_widget.tooltip.domain') }}</span>
              </span>
            </label>

            <input type="text" id="domain" name="domain" v-model="form.domain" @blur="$v.form.domain.$touch()" :class="{ error: $v.form.domain.$error }" :placeholder="$t('create_widget.placeholder.domain')" />
            <div v-if="$v.form.domain.$dirty">
              <span class="input-field__message error" v-if="!$v.form.domain.required">{{ $t('validation.required.domain') }}</span>
              <span class="input-field__message error" v-if="!$v.form.domain.domainCheck">{{ $t('validation.invalid.invalid_domain') }}</span>
            </div>
            <div v-if="errors && errors.domain">
              <span class="input-field__message error" v-for="error in errors.domain" :key="error">
                {{ error }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="sweet-content__footer button--right">
        <div class="grid end-xs middle-xs">
          <button type="button" @click="$refs.modal.close()" class="button--outline button--secondary">{{ $t('buttons.cancel') }}</button>
          <button type="submit" class="button--outline" :disabled="disabled">{{ $t('buttons.start_creating') }}</button>
        </div>
      </div>
    </form>
  </sweet-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { WidgetService } from '@/services/api/index'
import { required, helpers } from 'vuelidate/lib/validators'
import ImageCarouselFade from '@/components/global/ImageCarouselFade.vue'
import UtilsMixin from '@/plugins/mixins/utils'
import chatyStore from '@/utils/chaty-store'

const domainCheck = helpers.regex('domainCheck', /((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|localhost)/i)
export default {
  name: 'CreateWidgetModal',
  mixins: [UtilsMixin],
  components: {
    ImageCarouselFade
  },
  validations: {
    form: {
      name: {
        required
      },
      domain: {
        required,
        domainCheck
      }
    }
  },
  computed: {
    ...mapGetters({
      websites: 'user/getWebsites',
      domains: 'widget/getDomains'
    }),
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    },
    carouselImages() {
      return ['./images/onboard/onboard-1.webp', './images/onboard/onboard-2.webp', './images/onboard/onboard-3.webp']
    },
    totalWidgets() {
      return (this.domains || []).length
    }
  },

  data() {
    return {
      form: {
        name: '',
        domain: ''
      },
      isLoading: false,
      errors: null
    }
  },

  methods: {
    async open() {
      this.$refs.modal.open()
      this.logEvent('click_new_widget')

      if (
        this.isWixUser ||
        this.isShopifyUser ||
        this.isPrestashopUser ||
        this.isThinkificUser ||
        this.isDudaUser ||
        this.isNuvemUser ||
        this.isEcwidUser ||
        this.isBigCartelUser ||
        this.isSquareUser ||
        this.isBigCommerceUser ||
        this.isWebFlowUser ||
        this.isShoptetUser
      ) {
        if (!this.websites) {
          await this.fetchConnectedChannel()
        }
        if (this.websites && this.websites.length == 1) {
          this.form.domain = this.websites[0].attributes.url
        }
      }
    },

    resetForm() {
      this.form.name = ''
      this.form.domain = ''
      this.$v.$reset()
    },
    async createWidget() {
      this.clearData()
      this.isLoading = true
      this.error = null
      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        const response = await new WidgetService(this).createWidget({
          name: this.form.name,
          domain: this.form.domain
        })
        const widget = { ...response.data.attributes }
        // Manually set turn on widget
        widget.published = true
        this.$store.commit('widget/updateWidget', widget)
        this.$store.commit('widget/updateWidgetNewStatus', true)
        this.$store.commit('widget/updateWidgetSaveStatus', false)

        chatyStore.setItem('widget', JSON.stringify(response.data.attributes))
        chatyStore.setItem('widgetIsNew', true)

        await this.fetchAllWidgets()

        // Log event ot mix panel
        this.logEvent('start_new_widget')

        this.isLoading = false

        if (this.$refs.modal) {
          this.$refs.modal.close()
        }

        this.$store.commit('toast/update', { message: 'Widget Created!', type: 'success', status: true })
        this.$router.push(`update-widget/${response.data.attributes.identifier}`)
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Widget Created!', type: 'success', status: false })
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.errors
        } else {
          this.errors = ['An error occurred.']
        }
        this.isLoading = false
      }

      this.isLoading = false
    },
    clearData() {
      chatyStore.removeItem('widget')
      chatyStore.removeItem('widgetIsNew')
      chatyStore.removeItem('widgetCreationStep')
      this.$store.commit('widget/updateWidget', null)
      this.$store.commit('widget/updateWidgetChannels', null)
      this.$store.commit('widget/clearWidgetTriggers')
      this.$store.commit('widget/updateWidgetSettings', null)
      this.$store.commit('widget/updateWidgetName', null)
      this.$store.commit('widget/updateWidgetNewStatus', false)
      this.$store.commit('widget/updateWidgetSaveStatus', true)
      this.$store.commit('widget/updateWidgetFetchStatus', true)
      this.$store.commit('widget/updateError', null)
    }
  }
}
</script>

<style scoped>
.sweet-modal {
  overflow-x: hidden;
}
</style>

<style lang="scss">
@media (max-height: 700px) {
  .widget-modal-wrapper {
    .sweet-modal {
      max-width: 350px !important;

      .sweet-content__content {
        padding-bottom: 0;
      }

      .sweet-content__footer {
        padding-top: 5px;
      }
    }
  }
}

@media (max-width: 600px) {
  .ch-modal.widget-modal-wrapper {
    .sweet-modal {
      top: 50% !important;
      transform: translateY(-50%) scale(0.9) !important;
    }
  }
}
</style>
