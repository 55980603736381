export default {
  namespaced: true,

  state: () => ({
    leads: null,
    filters: null
  }),

  mutations: {
    updateLeads(state, leads) {
      state.leads = leads
    },
    updateLeadsFilters(state, filters) {
      state.filters = filters
    }
  },

  getters: {
    getLeads({ leads }) {
      return leads
    },
    getLeadsFilters({ filters }) {
      return filters
    }
  }
}
