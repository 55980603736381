import chatyStore from '@/utils/chaty-store'

export default {
  namespaced: true,

  state: () => ({
    user: null,
    card: null,
    subscription: null,
    ratings: null,
    userID: null,
    dashboardData: null,
    hasSetMixPanel: false,
    hasSetMixCrisp: false,
    shopifyStore: null,
    isWhiteLabel: false,
    websites: null,
    location: null,
    shopifyEmbedStatus: null
  }),

  mutations: {
    updateUser(state, user) {
      state.user = user
    },
    updateCard(state, included) {
      // accounting for null
      if (!included) {
        state.subscription = included
        return
      }

      const card = included.find(val => val.type == 'card')
      state.card = card ? card.attributes : null
    },
    updateSubscription(state, included) {
      // accounting for null
      if (!included) {
        state.subscription = included
        return
      }
      const subscription = included.find(val => val.type == 'subscription')
      state.subscription = subscription ? subscription.attributes : null
    },
    updateRatings(state, ratings) {
      state.ratings = ratings
    },
    updateUserID(state, userID) {
      state.userID = userID
    },
    updateDashboardData(state, dashboardData) {
      state.dashboardData = dashboardData
    },
    updateMixPanelStatus(state, status) {
      state.hasSetMixPanel = status
    },
    updateCrispStatus(state, status) {
      state.hasSetMixCrisp = status
    },
    updateShopifyStore(state, shopifyStore) {
      state.shopifyStore = shopifyStore
    },
    updateShopifyEmbedStatus(state, shopifyEmbedStatus) {
      state.shopifyEmbedStatus = shopifyEmbedStatus
    },
    updateWhileLabel(state, isWhiteLabel) {
      state.isWhiteLabel = isWhiteLabel
    },
    updateWebsite(state, website) {
      state.websites = website
    },
    updateLocation(state, location) {
      state.location = location
    }
  },

  getters: {
    /**
     * !IMPORTANT
     * Why saving the user data to local storage?
     * We show unverified email message to the user if the email is not verified. It creates a CLS issue.
     * Because the user data is not available in the initial load. So we save the user data to local storage and
     * show the message if the email is not verified.
     */
    getUser({ user }) {
      let data = null
      // if the user is null return from local storage
      if (!user) {
        data = JSON.parse(chatyStore.getItem('chaty_user_data'))
      } else {
        // save new user to local storage
        chatyStore.setItem('chaty_user_data', JSON.stringify(user))
        data = user
      }
      return data
    },
    getSubscription({ subscription }) {
      return subscription
    },
    getCard({ card }) {
      return card
    },
    getRatings({ ratings }) {
      return ratings
    },
    getUserID({ userID }) {
      return userID
    },
    getDashboardData({ dashboardData }) {
      return dashboardData
    },
    getMixPanelStatus({ hasSetMixPanel }) {
      return hasSetMixPanel
    },
    getCrispStatus({ hasSetMixCrisp }) {
      return hasSetMixCrisp
    },
    getShopifyStore({ shopifyStore }) {
      return shopifyStore
    },
    getShopifyEmbedStatus({ shopifyEmbedStatus }) {
      return shopifyEmbedStatus
    },
    getWebsites({ websites }) {
      return websites
    },
    getWhiteLabel({ isWhiteLabel }) {
      return isWhiteLabel
    },
    getLocation(state) {
      return state.location
    }
  }
}
