const chatyStore = {
  /**
   * @description: isShopifyUser is a getter function that returns a boolean value based on the user type.
   */
  get isShopifyUser() {
    const host = window.location.host
    return Boolean(window.shopifyBridgeApp) || host.startsWith('sp-go.') || host.startsWith('dev-sp-go.')
  },

  removeOpositeItem(key = '') {
    if (this.isShopifyUser) {
      return localStorage.removeItem(key)
    }

    sessionStorage.removeItem(key)
  },

  getItem(key = '') {
    this.removeOpositeItem(key)

    if (this.isShopifyUser) {
      return sessionStorage.getItem(key)
    }

    return localStorage.getItem(key)
  },

  setItem(key, value) {
    this.removeOpositeItem(key)

    if (this.isShopifyUser) {
      return sessionStorage.setItem(key, value)
    }

    localStorage.setItem(key, value)
  },

  removeItem(key = '') {
    if (this.isShopifyUser) {
      return sessionStorage.removeItem(key)
    }

    localStorage.removeItem(key)
  },

  clear() {
    if (this.isShopifyUser) {
      return sessionStorage.clear()
    }

    localStorage.clear()
  }
}

export default chatyStore
