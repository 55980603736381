<template>
  <div id="app">
    <router-view />
    <Toast />
  </div>
</template>

<script>
import Toast from '@/components/global/Toast.vue'
export default {
  components: {
    Toast
  }
}
</script>
