<template>
  <div class="spinner">
    <svg class="spinner" viewBox="0 0 50 50" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle class="path" cx="25" cy="25" r="20" fill="none"/></svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.spinner {
  display: inline-block;

  svg {
    width: 20px;
    height: 20px;

    circle {
      stroke: #b78deb;
    }
  }
}
</style>
