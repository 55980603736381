<template>
  <div class="lead">
    <!-- <transition name="slow"> -->
    <div class="leads-delete-container" v-if="selectedItems.length">
      <button class="button--transparent button--has-svg-left" @click="deleteLeads">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.666a1.333 1.333 0 011.334 1.334V4m2 0v9.333a1.334 1.334 0 01-1.334 1.334H4.667a1.334 1.334 0 01-1.334-1.334V4h9.334z" stroke="#49687E" stroke-linecap="round" stroke-linejoin="round"/></svg>
        Delete {{ selectedItems.length }} Lead{{ selectedItems.length > 1 ? 's' : '' }}
      </button>
      <a href="" @click.prevent="unCheckAllItems" class="close">
        <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM15 9l-6 6M9 9l6 6" stroke="#FF424D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> -->
        Cancel Selection{{ selectedItems.length > 1 ? 's' : '' }}
      </a>
    </div>
    <!-- </transition> -->
    <div class="lead-table m-b-0">
      <!-- <div class="lead--header">
        <span class="site-icon empty d-flex align-item-center justify-content-center">
          <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect x="11" width="16" height="16" rx="8" fill="#C6D7E3"/><rect x=".5" y="5.5" width="13" height="13" rx="2.5" fill="#C6D7E3" stroke="#fff"/><path d="M19.24 9.937a1.5 1.5 0 00-2.48 0l-6.773 9.97c-.676.996.037 2.343 1.24 2.343h13.545c1.204 0 1.917-1.347 1.241-2.343l-6.772-9.97z" fill="#C6D7E3" stroke="#fff"/></svg>
        </span>
        <a href="https://nike.com" target="_blank">nike.com</a>
        &nbsp;
        <span class="text-grey">(6 new leads today)</span>
      </div> -->
      <div class="responsive-table m-b-0">
        <table class="table" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th class="checkbox-wrapper">
                <div class="input-field__wrapper">
                  <input type="checkbox" id="changes1" :checked="!!selectedItems.length" />
                  <label for="changes1" class="checkbox" @click.prevent="selectMultiple" :class="{ indeterminate: isIndeterminate }"></label>
                </div>
              </th>
              <th class="lead_name">Widget Name</th>
              <th class="date">Domain & Date</th>
              <th class="name">Name</th>
              <th class="email">Email</th>
              <th class="phone">Phone</th>
              <th class="message">Message</th>
              <th class="action"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="lead in leads.data" :key="lead.id">
              <td class="checkbox-wrapper">
                <div class="checkbox__wrapper">
                  <input type="checkbox" :id="lead.id" :value="lead.id" v-model="selectedItems" />
                  <label :for="lead.id" class="checkbox"></label>
                </div>
              </td>
              <td class="lead_name">{{ lead.attributes.widget_name ? lead.attributes.widget_name : '--' }}</td>
              <td class="date">
                <div>
                  <div>{{ lead.attributes.domain_url }}</div>
                  <span class="text-grey">{{ lead.attributes.created_at | formatDateTime }}</span>
                </div>
              </td>
              <td class="name">{{ lead.attributes.name ? lead.attributes.name : '--' }}</td>
              <td class="email">{{ lead.attributes.email ? lead.attributes.email : '--' }}</td>
              <td class="phone">{{ lead.attributes.phone ? lead.attributes.phone : '--' }}</td>
              <td class="message">
                <div>{{ lead.attributes.message ? (lead.attributes.message.length > 50 ? lead.attributes.message.substring(0, 50) + '...' : lead.attributes.message) : '--' }}</div>
              </td>
              <td class="action">
                <button @click="$emit('showMessage', lead)" class="button--transparent svg--only">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0)" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"><path d="M.833 10S4.167 3.333 10 3.333 19.167 10 19.167 10 15.833 16.667 10 16.667.833 10 .833 10z"/><path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                </button>
                <button class="button--transparent svg--only" @click="$emit('deleteLeads', lead)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.666a1.333 1.333 0 011.334 1.334V4m2 0v9.333a1.334 1.334 0 01-1.334 1.334H4.667a1.334 1.334 0 01-1.334-1.334V4h9.334z" stroke="#49687E" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import { LeadsService } from '@/services/api/index'

export default {
  name: 'LeadComponent',
  props: ['leads'],
  watch: {
    selectedItems(value) {
      this.$emit('selectItem', value)
    }
  },
  data() {
    return {
      selectedItems: []
    }
  },
  computed: {
    // ...mapGetters({
    //   leads: 'leads/getLeads'
    // }),
    isIndeterminate() {
      if (this.selectedItems.length && this.selectedItems.length != this.leads.data.length) {
        return true
      }
      return false
    }
  },
  methods: {
    selectMultiple() {
      if (this.isIndeterminate) {
        this.unCheckAllItems()
      } else if (this.selectedItems.length == this.leads.data.length) {
        this.unCheckAllItems()
      } else {
        this.leads.data.map(lead => this.selectedItems.push(lead.id))
      }
    },
    unCheckAllItems() {
      this.selectedItems = []
    },
    async deleteLeads() {
      this.$store.commit('loader/updateStatus', true)
      try {
        await new LeadsService(this).deleteLead([...this.selectedItems])

        this.unCheckAllItems()
        this.$store.commit('loader/updateStatus', false)
        await this.getAllLeads()
        this.$store.commit('toast/update', { message: 'Widget Deleted!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Widget Deleted!', type: 'success', status: false })
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.errors
        }
        this.$store.commit('loader/updateStatus', false)
      }

      this.$store.commit('loader/updateStatus', false)
    }
  }
}
</script>

<style></style>
