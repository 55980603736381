import { mapGetters } from 'vuex'
import { UserService, WidgetService, DashboardService, PlanService, LeadsService, UtilityService } from '@/services/api/index'
import dayjs from 'dayjs'
import chatyCache from '@/plugins/chatyCache'
import chatyStore from '@/utils/chaty-store'

var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export default {
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      userID: 'user/getUserID',
      card: 'user/getCard',
      subscription: 'user/getSubscription',
      isWhiteLabel: 'user/getWhiteLabel'
    }),
    isPro() {
      if (this.user && this.user.plan_id != 1) return true
      return false
    },
    userFullName() {
      if (!this.user) {
        return null
      }
      if (this.user.first_name && !this.user.last_name) {
        return this.user.first_name
      } else if (!this.user.first_name && this.user.last_name) {
        return this.user.last_name
      } else if (this.user.first_name && this.user.last_name) {
        return `${this.user.first_name} ${this.user.last_name} `
      } else {
        return ''
      }
    },
    hasVat() {
      if (!this.user) return false
      // Check if country is Israel
      if (this.user.country_code && this.user.country_code.toUpperCase() == 'IL') return true
      return false
    },
    isBilledOnWix() {
      if (this.user && this.user.reg_source.toLowerCase() == 'wix' && this.user.billing_on.toLowerCase() == 'wix') {
        return true
      }
      return false
    },
    isBilledOnSquare() {
      if (this.user && this.user.reg_source.toLowerCase() == 'square' && this.user.billing_on.toLowerCase() == 'square') {
        return true
      }
      return false
    },
    isChatyUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'chaty') {
        return true
      }
      return false
    },
    isGoogleUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'google') {
        return true
      }
      return false
    },
    isWixUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'wix') {
        return true
      }
      return false
    },
    isThinkificUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'thinkific') {
        return true
      }
      return false
    },
    isShopifyUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'shopify') {
        return true
      }
      return false
    },
    isPrestashopUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'prestashop') {
        return true
      }
      return false
    },
    isDudaUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'duda') {
        return true
      }
      return false
    },

    isCustom1kPlan() {
      return this.subscription && this.subscription.plan.toLowerCase() === 'custom_1k' ? true : false
    },

    isBigCartelUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'bigcartel') {
        return true
      }
      return false
    },

    isNuvemUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'nuvemshop') {
        return true
      }
      return false
    },

    isEcwidUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'ecwid') {
        return true
      }
      return false
    },
    isSquareUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'square') {
        return true
      }
      return false
    },
    isBigCommerceUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'bigcommerce') {
        return true
      }
      return false
    },
    isWebFlowUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'webflow') {
        return true
      }
      return false
    },
    isShoptetUser() {
      if (this.user && this.user.reg_source.toLowerCase() == 'shoptet') {
        return true
      }
      return false
    },

    hasChatwayAccount() {
      if (!this.user) {
        return true
      }
      if (this.user && this.user.chatway_onboarding_step != null) {
        return true
      }
      return false
    },
    showChatwayConnection() {
      if (this.user) {
        return true
      }
      return false
    },
    hasVerifiedEmail() {
      return this.user && this.user.has_verified_email
    }
  },
  methods: {
    // Fetch User Profile
    async fetchUserProfile() {
      const update = response => {
        this.$store.commit('user/updateUser', response.data.attributes)
        this.$store.commit('user/updateUserID', response.data.id)
        this.$store.commit('user/updateCard', response.included)
        this.$store.commit('user/updateSubscription', response.included)
        chatyStore.setItem('plan', response.data.attributes.plan_id)
        if (response.data.attributes.reg_source == 'SHOPIFY') {
          this.fetchShopifyStore()
        }
      }

      // Check if user profile exists in cache
      chatyCache.exists(chatyCache.endpoints.userProfile, update)
      // Fetch user profile and update the UI
      const response = await new UserService(this).getUserProfile()
      update(response)
      // save user profile in cache
      chatyCache.save(chatyCache.endpoints.userProfile, response)
      return Promise.resolve(response)
    },

    // Fetch Shopify Store
    async fetchShopifyStore() {
      // check if app embed block exists in cache
      chatyCache.exists(chatyCache.endpoints.appEmbedBlock, response => {
        this.$store.commit('user/updateShopifyEmbedStatus', response)
      })

      const response = await new UserService(this).fetchConnectedApps()
      this.$store.commit('user/updateShopifyStore', response.data)

      const embedStatus = await this.getShopifyEmbedStatus(response.data)
      // save app embed block in cache
      chatyCache.save(chatyCache.endpoints.appEmbedBlock, embedStatus)
      this.$store.commit('user/updateShopifyEmbedStatus', embedStatus)
    },

    // Fetch dashboard data
    async fetchDashboardData(isRefreshing = false) {
      // check if the dashboard data exists in cache
      chatyCache.exists(chatyCache.endpoints.dashboard, response => {
        this.$store.commit('user/updateDashboardData', response)
      })

      if (!isRefreshing) {
        this.$store.commit('loader/updateStatus', true)
      }
      // Last 7 days data
      const startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const endDate = dayjs().format('YYYY-MM-DD')

      try {
        /**
         * @description
         * For PrestaShop user DashboardService response 500 error
         */
        const response = await new DashboardService(this).getDashboardData(startDate, endDate)
        this.$store.commit('user/updateDashboardData', response.data.attributes)
        this.$store.commit('loader/updateStatus', false)

        // save into the dashboard cache store
        chatyCache.save(chatyCache.endpoints.dashboard, response.data.attributes)
      } catch (error) {
        console.log(error)
        this.$store.commit('loader/updateStatus', false)
      }
    },

    // Fetch all user widgets
    async fetchAllWidgets() {
      // check if all widgets already exists in cache
      chatyCache.exists(chatyCache.endpoints.allWidgets, response => {
        this.$store.commit('widget/updateDomains', response)
      })

      if (!this.user) await this.fetchUserProfile()

      // Always fetch user ratings
      this.getUserRatings()

      // Last 7 days data
      const startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
      const endDate = dayjs().format('YYYY-MM-DD')

      // fetch all widgets list
      const response = await new WidgetService(this).getAllWidgets(startDate, endDate)
      this.$store.commit('widget/updateDomains', response)
      // save into the widget cache store
      chatyCache.save(chatyCache.endpoints.allWidgets, response)

      // check if crisp is loaded
      if (window.chatyCrispLoaded) {
        this.setCrispData()
      } else {
        window.addEventListener('chaty/crisp/loaded', () => {
          this.setCrispData()
        })
      }
    },

    // Fetch all plans
    async getAllPlans() {
      // check if the plans exists in cache
      chatyCache.exists(chatyCache.endpoints.plans, response => {
        this.plan = response
        this.$store.commit('plan/updatePlans', response)
      })
      // fetch all plans
      const response = await new PlanService(this).getPlans()
      this.plan = response.data
      this.$store.commit('plan/updatePlans', response.data)

      // save into the plans cache store
      chatyCache.save(chatyCache.endpoints.plans, response.data)
    },

    // Fetch all payments
    async getAllPayments() {
      try {
        // check if the payments exists in cache
        chatyCache.exists(chatyCache.endpoints.payment, response => {
          this.plan = response.data
          this.$store.commit('plan/updatePayments', response)
        })

        // fetch all payments
        const response = await new PlanService(this).getPayments()
        this.plan = response.data
        this.$store.commit('plan/updatePayments', response)

        // save into the payments cache store
        chatyCache.save(chatyCache.endpoints.payment, response)
      } catch (error) {
        console.log(error)
      }
    },

    // get location
    async getLocation() {
      const response = await new UtilityService(this).getLocation()
      const location = this.getAndParsedResponse(response).loc
      this.$store.commit('user/updateLocation', location)
    },

    getAndParsedResponse(browserData) {
      const getParsedData = browserData.data
        .trim()
        .split('\n')
        .reduce(function (obj, pair) {
          pair = pair.split('=')
          return (obj[pair[0]] = pair[1]), obj
        }, {})
      return getParsedData
    },

    // Fetch all leads filters
    async getAllLeads() {
      // check if the leads exists in cache
      chatyCache.exists(chatyCache.endpoints.leads, response => {
        this.$store.commit('leads/updateLeads', response)
      })

      // fetch all leads filters
      this.getAllLeadsFilters()
      // fetch all leads
      const response = await new LeadsService(this).getLeads({})
      this.$store.commit('leads/updateLeads', response)

      // save into the leads cache store
      chatyCache.save(chatyCache.endpoints.leads, response)
    },

    // Fetch all leads
    async getAllLeadsFilters() {
      const response = await new LeadsService(this).getLeadsFilters()
      this.$store.commit('leads/updateLeadsFilters', response.data.attributes)
    },

    // Fetch all leads
    async getUserRatings() {
      const { data } = await new UserService(this).fetchUserRatings()
      this.$store.commit('user/updateRatings', null)
      this.$store.commit('user/updateRatings', data.attributes)
    },

    async upgradeDudaPlan() {
      if (this.isWhiteLabel) {
        if (this.$refs && this.$refs.modal) {
          this.$refs.modal.open()
          await this.sleep(1000)
        }

        this.$refs.dudaUpgradeModal.open()
      } else if (!this.isFramed()) {
        await this.sleep(100)
        this.$refs.dudaUpgradeNoticeModal.open()
      } else {
        let allDudaPlans = ['a39d2b75-4cac-43ba-a429-1a545bc327c2', '383dcd51-4677-45fa-b7df-311f3fb414b6', '0dd46598-7415-4623-81b5-e3ab4b00081f', '09d72f6e-6bdb-4d17-a905-1a70e2803edc']

        if (process.env.VUE_APP_APP_ENV == 'production') {
          allDudaPlans = ['667bfd8b-9c6e-43be-83e2-d5d3b16d07d2', 'e4b37f1d-918c-468f-b3d2-e19bc99d3a01', '62cdd6a6-e611-40a6-a6db-c5a53bf4a278', 'e72299fa-3e45-4e76-98a0-41774dc6a5be']
        }

        let dudaUpgradeConfig = {
          type: 'upgradeApp',
          appId: 'c71ae854-91e3-4afa-9f9d-fe1362872def',
          plansList: allDudaPlans
        }

        // eslint-disable-next-line no-undef
        window['_dAPI'].upgrade(dudaUpgradeConfig)
      }
    },

    // Set Crisp Data
    setCrispData() {
      const $crisp = window.$crisp
      if (this.$store.getters['user/getCrispStatus']) return
      let isAdmin = chatyStore.getItem('isAdmin')

      if (isAdmin) {
        $crisp.push(['do', 'session:reset'])
        $crisp.push(['do', 'chat:hide'])
      } else {
        // Set crisp data
        window.CRISP_TOKEN_ID = btoa(this.user.user_identifier)
        $crisp.push(['do', 'session:reset'])
        const domains = this.$store.getters['widget/getDomains'].map(domain => domain.url).join(', ')
        const renewalDate = this.subscription ? (this.subscription.next_renewal_date ? this.subscription.next_renewal_date : '') : ''
        const userType = this.isWixUser
          ? 'Chaty.app Wix'
          : this.isShopifyUser
          ? 'Chaty.app Shopify'
          : this.isDudaUser
          ? 'Chaty.app Duda'
          : this.isNuvemUser
          ? 'Chaty.app Nuvemshop'
          : this.isEcwidUser
          ? 'Chaty.app Ecwid'
          : 'Chaty.app'
        const data = [
          ...(this.user ? [['plan', this.user.plan_name]] : []),
          ...(userType ? [['user_type', userType]] : []),
          ...(this.user ? [['signup_date', this.user.created_at]] : []),
          ...(domains ? [['domain', domains]] : []),
          ['renewal_date', renewalDate],
          ['quick', `https://api.chaty.app/page505/clients?search=${this.user.email}`]
        ]

        $crisp.push(['set', 'user:email', [this.user.email]])
        $crisp.push(['set', 'session:data', [[...data]]])
      }
      this.$store.commit('user/updateCrispStatus', true)
    },

    async fetchConnectedChannel() {
      try {
        const channelType = this.isWixUser
          ? 'WIX'
          : this.isDudaUser
          ? 'DUDA'
          : this.isThinkificUser
          ? 'THINKIFIC'
          : this.isBigCartelUser
          ? 'BIGCARTEL'
          : this.isNuvemUser
          ? 'NUVEMSHOP'
          : this.isBigCommerceUser
          ? 'BIGCOMMERCE'
          : this.isWebFlowUser
          ? 'WEBFLOW'
          : this.isEcwidUser
          ? 'ECWID'
          : this.isSquareUser
          ? 'SQUARE'
          : this.isPrestashopUser
          ? 'PRESTASHOP'
          : this.isShoptetUser
          ? 'SHOPTET'
          : 'SHOPIFY'
        const { data } = await new WidgetService(this).getConnectedChannel(channelType)
        this.$store.commit('user/updateWebsite', data)
      } catch (error) {
        console.log(error)
      }
    },

    // Mixpanel Event logger
    // ToDo: event param = '' empty string
    logEvent() {
      // eslint-disable-next-line no-undef
      // mixpanel.track(event)
    },

    resetStore() {
      this.$store.commit('plan/updatePlans', null)
      this.$store.commit('plan/updatePayments', null)
      this.$store.commit('user/updateUser', null)
      this.$store.commit('user/updateSubscription', null)
      this.$store.commit('user/updateUserID', null)
      this.$store.commit('user/updateDashboardData', null)
      this.$store.commit('user/updateMixPanelStatus', null)
      this.$store.commit('widget/updateDomains', null)
    }
  }
}
