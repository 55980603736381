<template>
  <div class="actions w-100">
    <ShopifyEmbedApp v-if="isVisible" />
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DashboardService } from '@/services/api/index'
import chatyCache from '@/plugins/chatyCache'
import ShopifyEmbedApp from '@/components/global/ShopifyEmbedApp.vue'

export default {
  name: 'ShopifyEmbedAppPlaceholder',
  components: {
    ShopifyEmbedApp
  },
  computed: {
    ...mapGetters({
      store: 'user/getShopifyStore',
      shopifyEmbedStatus: 'user/getShopifyEmbedStatus'
    }),
    isVisible() {
      if (!this.isShopifyUser || !this.shopifyEmbedStatus) return
      return !this.shopifyEmbedStatus.is_embedded_app_enabled && !this.shopifyEmbedStatus.is_script_injected
    },
    shop() {
      if (!this.shopifyEmbedStatus) return
      return this.shopifyEmbedStatus.shop
    }
  },
  data() {
    return {
      isVerifying: false,
      hasError: false
    }
  },
  methods: {
    async completeSetup() {
      this.isVerifying = true
      this.hasError = false
      const response = await new DashboardService(this).getShopifyEmbedDeepLink({ shop: this.shop })
      window.open(response.data.attributes, '_blank')
      document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this))
    },

    onVisibilityChange() {
      if (!document.hidden && (this.isVerifying || this.hasError)) {
        // call the shopify api to verify the status in the background
        setTimeout(async () => {
          const response = await this.getShopifyEmbedStatus(this.store)

          this.$store.commit('user/updateShopifyEmbedStatus', response)
          this.isVerifying = false
          this.hasError = !response.is_embedded_app_enabled
          // Save the response to cache
          chatyCache.save(chatyCache.endpoints.appEmbedBlock, response)
        }, 3000)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange.bind(this))
  }
}
</script>
<style lang="scss" scoped></style>
