<template>
  <div class="lead">
    <div class="lead-table">
      <div class="responsive-table">
        <table class="table" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th class="checkbox-wrapper"></th>
              <th class="lead_name">Widget Name</th>
              <th class="date">Domain + Date</th>
              <th class="name">Name</th>
              <th class="email">Email</th>
              <th class="phone">Phone</th>
              <th class="message">Message</th>
              <th class="action"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="8" class="empty--state">
                <div>
                  <div class="info">
                    <img src="@/assets/images/empty-state/empty.webp" loading="lazy" alt="" />
                    <div class="copy">
                      <p class="text-center">
                        No leads available.
                        <br />
                        Your contact form channel leads
                        <br />
                        will appear in this table
                      </p>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.table tbody tr:hover td {
  background-color: #fff !important;
}
.empty--state {
  min-height: 300px;
  height: 50vh !important;

  & > div {
    position: relative;
    height: 100%;

    .info {
      display: inline-flex;
      align-items: center;
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);

      p {
        color: #83a1b7;
      }
    }
  }
}
</style>
