<template>
  <div>
    <div class="leads-page">
      <h4 class="page-header">Contact Form Leads</h4>
      <div class="cta grid bottom-xs">
        <ChatyDateSelectComponent />

        <div class="dropdown code-dropdown m-l-20">
          <button class="button--transparent button--has-svg-left">
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.335 1H1.668l6.667 7.883v5.45L11.668 16V8.883L18.335 1z" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
            Filter
          </button>
        </div>

        <button class="button--transparent button--has-svg-left m-l-10">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.332 10.5v6.667a1.667 1.667 0 001.667 1.666h10a1.667 1.667 0 001.666-1.666V10.5M13.335 5.5L10 2.167 6.668 5.5M10 2.167V13" stroke="#83A1B7" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
          Export to .CSV
        </button>
      </div>
      <LeadComponent :leads="leads" />
    </div>
    <ShopifyEmbedAppPlaceholder>
      <img svg-inline src="@/assets/images/empty-state/leads-check.png" alt="" />
      <div class="buttons">
        <button @click="$refs.createWidgetModal.open()" type="button" class="button button--has-svg-left text-normal">{{ $t('widgets.create_widget') }}</button>
      </div>
    </ShopifyEmbedAppPlaceholder>
    <CreateWidgetModal ref="createWidgetModal" />
  </div>
</template>

<script>
import ChatyDateSelectComponent from '@/components/global/ChatyDateSelect.vue'
import LeadComponent from '@/components/leads/LeadComponent.vue'
import CreateWidgetModal from '@/components/modals/CreateWidget.vue'
import ShopifyEmbedAppPlaceholder from './ShopifyEmbedAppPlaceholder.vue'

export default {
  components: {
    ChatyDateSelectComponent,
    LeadComponent,
    CreateWidgetModal,
    ShopifyEmbedAppPlaceholder
  },
  data() {
    return {
      leads: {
        data: [
          {
            type: 'contact_form_lead',
            id: '62',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'compress Kids whiteboard',
              name: 'Amya Mohr',
              email: 'Elian.Braun82@gmail.com',
              phone: '823-448-5367',
              created_at: '2022-04-28T08:33:19.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '61',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'withdrawal feed',
              name: 'Winnifred Dach',
              email: 'Howard.Watsica@gmail.com',
              phone: '912-475-9952',
              created_at: '2022-04-28T08:33:18.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '60',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'Exclusive Stand-alone',
              name: 'Cathy Greenfelder',
              email: 'Wilbert_Schmidt@yahoo.com',
              phone: '477-227-2425',
              created_at: '2022-04-28T08:33:17.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '59',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'hybrid withdrawal e-markets Shores Generic',
              name: 'Brando Gutkowski',
              email: 'Lea64@yahoo.com',
              phone: '735-931-9565',
              created_at: '2022-04-28T08:33:16.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '58',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'JSON quantify array Tasty bypass',
              name: 'Alexandria Metz',
              email: 'Lamont_OHara@gmail.com',
              phone: '854-325-6015',
              created_at: '2022-04-28T08:33:15.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '57',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'stable stable Swaziland pink',
              name: 'Wellington Connelly',
              email: 'Maud14@hotmail.com',
              phone: '617-850-8721',
              created_at: '2022-04-28T08:33:13.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '56',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'SSL Cheese sensor plug-and-play Response',
              name: 'Shanny Feeney',
              email: 'Karli.Connelly18@gmail.com',
              phone: '531-511-3057',
              created_at: '2022-04-28T08:33:09.000000Z'
            }
          },
          {
            type: 'contact_form_lead',
            id: '55',
            attributes: {
              domain_url: 'jstrainer.com',
              widget_name: 'Jaswinder Singh',
              message: 'hacking override Gloves strategic',
              name: 'Dusty Wiegand',
              email: 'Sunny_Gerhold@gmail.com',
              phone: '614-778-4013',
              created_at: '2022-04-28T08:33:07.000000Z'
            }
          }
        ],
        meta: { pagination: { total: 17, count: 10, per_page: 10, current_page: 1, total_pages: 2 } },
        links: {
          self: 'https://dev-api.chaty.app/api/contactform/leads?page=1',
          first: 'https://dev-api.chaty.app/api/contactform/leads?page=1',
          next: 'https://dev-api.chaty.app/api/contactform/leads?page=2',
          last: 'https://dev-api.chaty.app/api/contactform/leads?page=2'
        }
      }
    }
  },

  methods: {}
}
</script>

<style></style>
