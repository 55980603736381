export default {
  namespaced: true,

  state: () => ({
    plans: null,
    payments: null,
    paymentUrl: null
  }),

  mutations: {
    updatePlans(state, plans) {
      state.plans = plans
    },
    updatePayments(state, response) {
      if (response) {
        state.payments = response.data
      }
    },
    updatePaymentUrl(state, url) {
      state.paymentUrl = url
    }
  },

  getters: {
    getPlans(state) {
      return state.plans
    },
    getPayments(state) {
      return state.payments
    },
    getPaymentUrl(state) {
      return state.paymentUrl
    }
  }
}
