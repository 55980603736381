<template>
  <div class="form-area" :class="{ 'is-validating': isLoading }">
    <div class="header">
      <img width="130" height="56" loading="lazy" src="images/svg/logo.svg" alt="site logo" />
    </div>
    <div class="main-text">
      <h3>{{ $t('title.signup') }}</h3>
    </div>
    <p class="supporting-text text-center text-grey">
      {{ $t('signup.few_steps_away') }}
    </p>
    <div class="main-form">
      <form @submit.prevent="registerUser">
        <div class="input-field__wrapper">
          <label for="email">{{ $t('forms.email') }}</label>

          <AutoFillMail :value="form.email" @update:value="v => (form.email = v)" ref="autoFillMail">
            <input
              type="email"
              name="email"
              v-model="form.email"
              @blur="$v.form.email.$touch()"
              @keydown.tab="$refs.autoFillMail.confirmAutoComplete"
              @keydown.enter="$refs.autoFillMail.confirmAutoComplete"
              @keydown.space="$refs.autoFillMail.confirmAutoComplete"
              @keydown.right="$refs.autoFillMail.confirmAutoComplete"
              @click="ev => $refs.autoFillMail.confirmAutoComplete(ev)"
              :class="{ error: $v.form.email.$error }"
            />
          </AutoFillMail>

          <div v-if="$v.form.email.$dirty">
            <span class="input-field__message error" v-if="!$v.form.email.email">{{ $t('validation.invalid.email') }}</span>
            <span class="input-field__message error" v-if="!$v.form.email.required">{{ $t('validation.required.email') }}</span>
          </div>
          <div v-if="errors && errors.email">
            <span class="input-field__message error" v-for="message in errors.email" :key="message">{{ message }}</span>
          </div>
        </div>

        <button class="button--full button--darker" :disabled="disabled">
          {{ $t('buttons.signup') }}
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 9h14M9 2l7 7-7 7" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </form>
    </div>
    <AuthProviders @updateLoadingStatus="status => (this.isLoading = status)">Or sign up with</AuthProviders>
    <div class="form-area__footer">
      <span class="form-area__footer__text">Already have an account?</span>
      <router-link to="/login" class="form-area__footer__link">Sign in</router-link>
    </div>
  </div>
</template>
<script>
import { AuthService } from '@/services/api/index'
import AutoFillMail from '@/components/global/AutoFillMail.vue'
import { required, email } from 'vuelidate/lib/validators'
import AuthProviders from '@/components/global/AuthProviders.vue'
import Cookies from 'js-cookie'
export default {
  components: {
    AutoFillMail,
    AuthProviders
  },
  metaInfo: {
    title: 'Register | Chaty'
  },
  validations: {
    form: {
      email: {
        required,
        email: v => email(v.toLowerCase())
      }
    }
  },
  data() {
    return {
      form: {
        email: ''
      },
      isLoading: false,
      errors: null
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },
  methods: {
    async registerUser() {
      /**
       * @const {boolean} isVisible
       * @description check if the auto complete suggestion is visible
       * - the suggesation will appear frist time when user submit the form
       * - on first submit it will provide with true value
       * - on the second submit it will provide with false value
       */
      const isVisible = this.$refs.autoFillMail.checkSuggesation()
      if (isVisible) return

      this.isLoading = true
      this.error = null

      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        let src = sessionStorage.getItem('utm_source') || 'chaty'

        const response = await new AuthService(this).register({ email: this.form.email.toLowerCase(), src, landing_url: Cookies.get('chaty_landing_url'), referrer_url: Cookies.get('chaty_referrer_url') })

        // Get access token from response
        const access_token = response.data.attributes.access_token

        // Add access token to cookie
        // Cookies.set('ch_token', access_token, { expires: 7 })
        localStorage.setItem('ch_token', access_token)

        // Navigate to home page
        this.$router.push('home')
        this.isLoading = false
        this.$store.commit('toast/update', { message: 'Account Created Successfully!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Account Created Successfully!', type: 'success', status: false })
      } catch (error) {
        this.errors = error.response.data.errors
        this.isLoading = false
      }

      this.isLoading = false
    }
  }
}
</script>
