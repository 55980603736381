<template>
  <div class="toast-container" :class="{ active: toast.status == true }">
    <div class="toast" :class="[toast.type ? `toast--${toast.type}` : '']">
      <button class="button--text" @click="hideToast">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 5L5 15M5 5l10 10" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </button>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="toast.type == 'success'" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="12" cy="12" r="12" fill="#5BE179"/><path d="M6 12.148L9.575 16 17 8" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="toast.type == 'error'" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="12" cy="12" r="12" fill="#FF424D"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.95 8.464a1 1 0 10-1.414-1.414L12 10.586 8.464 7.05A1 1 0 007.05 8.464L10.586 12 7.05 15.536a1 1 0 101.414 1.414L12 13.414l3.536 3.536a1 1 0 001.414-1.414L13.414 12l3.536-3.536z" fill="#fff"/></svg>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="12" cy="12" r="12" fill="#B78DEB"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13 7a1 1 0 10-2 0v7a1 1 0 102 0V7zm0 10a1 1 0 10-2 0 1 1 0 002 0z" fill="#fff"/></svg>

      <div class="content">{{ toast.message }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  computed: {
    ...mapGetters({
      toast: 'toast/get'
    })
  },
  methods: {
    hideToast() {
      if (this.toast.status) {
        this.$store.commit('toast/update', { message: '', type: this.toast.type, status: false })
      }
    }
  },

  mixins: [clickaway]
}
</script>
