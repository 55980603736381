<template>
  <div class="chaty-channel">
    <div class="widget-channels">
      <template v-if="channels && channels.length">
        <span class="widget-icon" v-for="channel in displayChannel" :key="channel" v-html="getChannelIcon(channel)"></span>
        <span class="widget-icon widget-count" v-if="channels.replace(/\[|\]/g, '').split(',').length > 5">+{{ channels.replace(/\[|\]/g, '').split(',').length - 5 }}</span>
      </template>
      <span class="widget-icon empty" v-else></span>
    </div>
  </div>
</template>

<script>
import ChannelMixin from '@/plugins/mixins/channel'

export default {
  name: 'WidgetChannels',
  mixins: [ChannelMixin],
  props: {
    channels: {
      type: []
    }
  },
  computed: {
    displayChannel() {
      const channels = this.channels.replace(/\[|\]/g, '').split(',')
      if (channels.length > 5) {
        return channels.slice(0, 5)
      }
      return channels
    }
  }
}
</script>

<style scoped></style>
