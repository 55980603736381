<template>
  <div class="image-carousel-fade" @mouseenter.prevent="pause = true" @mouseleave.prevent="pause = false">
    <img :class="classNames" :src="currentImage" loading="lazy" width="450" height="253" />
    <div class="dots">
      <div v-for="(_img, index) in images" class="dots__item" :class="{ active: activeIndex === index }" :key="index" @click.prevent="changeImage(index)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageCarouselFade',
  props: ['urls'],
  data() {
    return {
      activeIndex: 0,
      pause: false,
      classNames: '',
      timeId: null
    }
  },
  computed: {
    images() {
      return this.urls || []
    },
    currentImage() {
      return this.images[this.activeIndex]
    }
  },
  methods: {
    changeImage(index) {
      clearInterval(this.timeId)
      this.classNames = 'fadeOutImage'
      setTimeout(() => {
        this.activeIndex = index
        this.classNames = 'fadeInImage'
        this.init()
      }, 400)
    },
    addNextImage() {
      if (this.activeIndex === this.images.length - 1) {
        this.activeIndex = 0
        this.classNames = 'fadeInImage'
        return
      }

      this.activeIndex = this.activeIndex + 1
      this.classNames = 'fadeInImage'
    },

    fadeOutImage() {
      if (this.pause) return
      this.classNames = 'fadeOutImage'
      setTimeout(() => {
        this.addNextImage()
      }, 400)
    },
    init() {
      // update the image in every 3 seconds
      this.timeId = setInterval(this.fadeOutImage, 3000)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.image-carousel-fade {
  position: relative;

  .dots {
    position: absolute;
    bottom: 12px;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    box-shadow: 0px -1.3333848714828491px 13.33384895324707px 0px #03114429;
    background-color: #fff;
    border-radius: 32px;
    padding: 4px 5px;
    display: flex;
    align-items: center;
    column-gap: 6px;

    &__item {
      --size: 5px;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background-color: #f9f6fd;
      transition: background-color 0.2s;
      cursor: pointer;

      &.active {
        background-color: #b78deb;
      }
    }
  }
}

img {
  vertical-align: middle;
  width: 100%;
  height: auto;
}

@keyframes fadeOutImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeOutImage {
  animation: fadeOutImage;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}

.fadeInImage {
  animation: fadeInImage;
  animation-duration: 500ms;
  animation-iteration-count: 1;
}
</style>
