export default {
  namespaced: true,

  state: () => ({
    toast: {
      message: '',
      type: '',
      status: false
    }
  }),

  mutations: {
    update(state, toast) {
      state.toast = toast
    }
  },

  getters: {
    get(state) {
      return state.toast
    }
  }
}
