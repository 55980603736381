<template>
  <div></div>
</template>

<script>
import chatyStore from '@/utils/chaty-store'

export default {
  async created() {
    chatyStore.setItem('ch_reset_token', this.$route.params.token)
    chatyStore.setItem('ch_reset_email', this.$route.query.email)
    this.$router.push('/password/reset')
  }
}
</script>
