<template>
  <div class="password-field">
    <slot></slot>
    <span class="eye-btn" @click="togglePasswordVisibility">
      <img v-if="type === 'password'" width="20" src="@/assets/svg/icons/eye-open.svg" />
      <img v-else width="20" src="@/assets/svg/icons/eye-close.svg" />
    </span>
  </div>
</template>
<script>
export default {
  name: 'PasswordEyeButton',
  props: {
    type: {
      type: String,
      required: true,
      default: 'password'
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.$emit('update:type', this.type === 'password' ? 'text' : 'password')
    }
  }
}
</script>

<style lang="scss">
.password-field {
  position: relative;

  .eye-btn {
    position: absolute;
    display: flex;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  input {
    padding-right: 37px !important;
  }
}
</style>
