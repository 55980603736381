import axios from 'axios'
let scriptLoaded = false
let timeoutId = null

function loadScript() {
  if (scriptLoaded) {
    // cancel events
    document.removeEventListener('mousemove', loadScript)
    document.removeEventListener('scroll', loadScript)
    clearTimeout(timeoutId)
    return
  }

  const template = document.getElementById('lazy-load-header-scripts')

  if (template) {
    const markup = template.content
    document.head.appendChild(markup)
    scriptLoaded = true
  }
}

export const loadHeaderTemplateAfterFCPCount = () => {
  if (scrollY > 0) {
    return loadScript()
  }

  // on some DOM events
  document.addEventListener('mousemove', loadScript, { once: true })
  document.addEventListener('scroll', loadScript, { once: true })
  // on timeout
  timeoutId = setTimeout(loadScript, 3000)
}

export const reportWebVitals = () => {
  const baseURL = process.env.VUE_APP_API_BASE_URL

  if (!window.shopifyBridgeApp) return

  /* eslint-disable no-undef */
  window.shopifyBridgeApp.hooks.set(AppBridge.LifecycleHook.DispatchAction, next => action => {
    const { group, clientInterface } = action

    if ('WebVitals' === group) {
      axios.post(`${baseURL}web-vitals`, {
        metric_name: action.payload.metricName,
        value: String(action.payload.value),
        location: window.location.pathname,
        client_interface: clientInterface
      })
    }

    next(action)
  })
}
