<template>
  <div class="form-area" :class="{ 'is-validating': isLoading }">
    <div class="header">
      <img width="130" height="56" loading="lazy" src="images/svg/logo.svg" alt="site logo" />
    </div>
    <div class="main-text">
      <h3>{{ $t('title.login') }}</h3>
    </div>
    <div class="main-form">
      <form @submit.prevent="loginUser">
        <div class="input-field__wrapper">
          <label for="email">{{ $t('forms.email') }}</label>
          <input type="email" name="email" v-model="form.email" @blur="$v.form.email.$touch()" :class="{ error: $v.form.email.$error }" />
          <div v-if="$v.form.email.$dirty">
            <span class="input-field__message error" v-if="!$v.form.email.email">{{ $t('validation.invalid.email') }}</span>
            <span class="input-field__message error" v-if="!$v.form.email.required">{{ $t('validation.required.email') }}</span>
          </div>
          <div v-if="errors && errors.email">
            <span class="input-field__message error" v-for="message in errors.email" :key="message">{{ message }}</span>
          </div>
        </div>

        <div class="input-field__wrapper">
          <div class="d-flex align-items-center justify-content-between password-input-label">
            <label for="email">{{ $t('forms.password') }}</label>
            <router-link to="/forgot-password" class="forgot-password-link">{{ $t('buttons.forgot_password') }}</router-link>
          </div>
          <PasswordEyeButton :type="passwordType" @update:type="e => (passwordType = e)">
            <input :type="passwordType" v-model="form.password" name="email" @blur="$v.form.password.$touch()" :class="{ error: $v.form.password.$error }" />
          </PasswordEyeButton>
          <div v-if="$v.form.password.$dirty">
            <span class="input-field__message error" v-if="!$v.form.password.required">{{ $t('validation.required.password') }}</span>
          </div>
          <span class="input-field__message error" v-if="errors && errors.message">
            {{ errors.message }}
          </span>
        </div>
        <button class="button--full button--darker" :disabled="disabled">
          Sign In
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 9h14M9 2l7 7-7 7" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </form>
    </div>
    <AuthProviders>Or sign in with</AuthProviders>
    <div class="form-area__footer">
      <span class="form-area__footer__text">Do not have an account?</span>
      <router-link to="/register" class="form-area__footer__link">Register</router-link>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { AuthService } from '@/services/api/index'
import { required, email } from 'vuelidate/lib/validators'
import PasswordEyeButton from '@/components/global/PasswordEyeButton.vue'
import AuthProviders from '@/components/global/AuthProviders.vue'
import chatyStore from '@/utils/chaty-store'

export default {
  metaInfo: {
    title: 'Login | Chaty'
  },
  components: {
    PasswordEyeButton,
    AuthProviders
  },

  validations: {
    form: {
      email: {
        required,
        email: val => email(val.toLowerCase())
      },
      password: {
        required
      }
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      isLoading: false,
      errors: null,
      passwordType: 'password'
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },
  methods: {
    async loginUser() {
      this.isLoading = true
      this.error = null

      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        const authResponse = await new AuthService(this).login({ email: this.form.email.toLowerCase(), password: this.form.password })

        // Get access token from response
        const access_token = authResponse.data.attributes.access_token
        chatyStore.setItem('ch_token', access_token)

        const source = chatyStore.getItem('utm_source')
        if (source && source == 'square') {
          chatyStore.removeItem('utm_source')
          this.$router.push('/dashboard/square')
        }

        // check for last visit and remove from cookies
        const lastVisit = Cookies.get('ch_last_visit')
        Cookies.remove('ch_last_visit')

        this.logEvent('login')

        // Navigate to home page
        if (lastVisit) {
          return this.$router.push(`/${lastVisit}`)
        }
        return this.$router.push('/home')
      } catch (error) {
        if (error.response) {
          this.errors = error.response.data.errors ? error.response.data.errors : error.response.data
        } else {
          this.errors = { message: 'An error occurred ' }
        }
        this.isLoading = false
      }

      this.isLoading = false
    }
  },

  created() {
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage('chaty/logout_from_iframe', '*')
    }
  }
}
</script>

<style lang="scss" scoped>
.password-input-label {
  margin-bottom: 10px;
  label {
    margin-bottom: 0 !important;
    font-size: 14px;
  }

  .forgot-password-link {
    font-size: 14px;
    color: #b78deb;
    transition: color 0.3s;
    &:hover {
      color: #a16ee0;
    }
  }
}
</style>
