import axios from 'axios'
export default class WixService {
  constructor(args) {
    this.axios = args.$axios
  }

  makeRating({ rating, review, channel }) {
    return this.axios.post(`apps/rating`, {
      channel,
      rating,
      ...(review && { review })
    })
  }

  setReminder({ remind_after, channel }) {
    return this.axios.post(`apps/rating/reminder`, {
      channel: channel,
      remind_after
    })
  }

  sendMail({ name, message }) {
    const formData = new FormData()
    formData.append('email', 'contact@premio.io')
    formData.append('name', name)
    formData.append('message', message)
    return axios.post(`https://premioapps.com/premio/send-message-api.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
