class CachePlugin {
  #isSupported = 'caches' in window
  #cacheVersion = 'chaty-api-cache'
  #endpoints = {
    userProfile: 'user-profile',
    allWidgets: 'all-widgets',
    widgets: 'widgets',
    plans: 'plans',
    payment: 'payment',
    dashboard: 'dashboard',
    leads: 'leads',
    appEmbedBlock: 'app-embed-block'
  }

  async #remove(endpoint) {
    // remove all entries from the cache
    if (!this.#isSupported) return
    const cache = await caches.open(this.#cacheVersion)

    // remove al entries except the #endpoints (remove garbage collection)
    for (let cacheItem of await cache.keys()) {
      const request = new URL(cacheItem.url)
      const pathname = request.pathname.substring(1)

      if (Object.values(this.#endpoints).indexOf(pathname) === -1) {
        cache.delete(cacheItem)
      }
    }

    if (this.#validateEndpoint(endpoint)) {
      console.log('delete', endpoint)
      cache.delete(new Request(endpoint))
    }
  }

  #validateEndpoint(endpoint) {
    if ('string' !== typeof endpoint) return
    if (Object.values(this.#endpoints).indexOf(endpoint) === -1) {
      console.error('Endpoint is not supported.')
      return false
    }

    return true
  }

  async save(endpoint, response) {
    if (!this.#validateEndpoint(endpoint) || !this.#isSupported) return
    await this.#remove()
    const cache = await caches.open(this.#cacheVersion)
    cache.put(endpoint, new Response(JSON.stringify(response)))
  }

  exists(endpoint, callback) {
    if (!this.#validateEndpoint(endpoint) || !this.#isSupported) return

    if ('function' !== typeof callback) {
      return console.error('Callback is not a function.')
    }

    caches.open(this.#cacheVersion).then(cache => {
      cache.match(endpoint).then(response => {
        if (response) {
          return response.json().then(callback)
        }
      })
    })
  }

  get endpoints() {
    return this.#endpoints
  }
}

const chatyCache = new CachePlugin()
export default chatyCache
