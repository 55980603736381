<template>
  <div class="chaty-select">
    <div class="chaty-select__wrapper">
      <div class="dropdown" :class="{ active: isOpen }" v-on-clickaway="toggleState">
        <div class="input-field__wrapper" @click.stop="isOpen = !isOpen">
          <input type="text" :class="{ 'has--icon': hasIcon }" name="select" readonly :value="selectedItem ? selectedItem.name : ''" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hasIcon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 4H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM16 2v4M8 2v4M3 10h18" stroke="currentColor" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>

        <transition name="slow">
          <div class="dropdown__body" v-if="isOpen">
            <ul>
              <li v-for="item in list" :key="item.value">
                <a :class="{ active: selectedItem && selectedItem.value == item.value }" href="#" @click.prevent="selectItem(item)">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
export default {
  mixins: [clickaway],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    hasIcon: {
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      selectedItem: null
    }
  },
  methods: {
    toggleState() {
      this.isOpen = false
    },
    selectItem(item) {
      this.$emit('input', item)
      this.selectedItem = item
      this.isOpen = false
    }
  },
  created() {
    this.selectedItem = this.list[0]
  }
}
</script>

<style></style>
