<template>
  <div v-if="isVisible" class="shopify-embed">
    <div class="shopify-embed__header d-flex justify-content-between align-items-center gap-2">
      <div>
        <h2 class="shopify-embed__title">Installation Incomplete</h2>
        <p class="shopify-embed__description">We're almost done, please add Chaty to your theme</p>
      </div>
      <button :disabled="!shop" v-if="!isVerifying" @click.prevent="completeSetup" class="button--outline button--secondary button--small danger">
        <template v-if="hasError">Setup Incomplete. Please Check Again</template>
        <template v-else>Complete Setup</template>
      </button>
      <button v-else class="button--outline button--secondary button--small danger button--has-spinner justify-content-center">
        <svg class="spinner" viewBox="0 0 50 50" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle class="path" cx="25" cy="25" r="20" fill="none"/></svg>
        Verifying...
      </button>
    </div>
    <ul>
      <li>Click on "Complete Setup"</li>
      <li>Make sure that the "Chaty" toggle on the left sidebar is turned on</li>
      <li>Click on the "Save" button</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DashboardService } from '@/services/api/index'
import chatyCache from '@/plugins/chatyCache'

export default {
  name: 'ShopifyEmbedApp',
  computed: {
    ...mapGetters({
      store: 'user/getShopifyStore',
      shopifyEmbedStatus: 'user/getShopifyEmbedStatus'
    }),
    isVisible() {
      if (!this.isShopifyUser || !this.shopifyEmbedStatus) return
      return !this.shopifyEmbedStatus.is_embedded_app_enabled && !this.shopifyEmbedStatus.is_script_injected
    },
    shop() {
      if (!this.shopifyEmbedStatus) return
      return this.shopifyEmbedStatus.shop
    }
  },
  data() {
    return {
      isVerifying: false,
      hasError: false
    }
  },
  methods: {
    async completeSetup() {
      this.isVerifying = true
      this.hasError = false
      const response = await new DashboardService(this).getShopifyEmbedDeepLink({ shop: this.shop })
      window.open(response.data.attributes, '_blank')
      document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this))
    },

    onVisibilityChange() {
      if (!document.hidden && (this.isVerifying || this.hasError)) {
        // call the shopify api to verify the status in the background
        setTimeout(async () => {
          const response = await this.getShopifyEmbedStatus(this.store)

          this.$store.commit('user/updateShopifyEmbedStatus', response)
          this.isVerifying = false
          this.hasError = !response.is_embedded_app_enabled
          // Save the response to cache
          chatyCache.save(chatyCache.endpoints.appEmbedBlock, response)
        }, 3000)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilityChange.bind(this))
  }
}
</script>
<style lang="scss" scoped>
.shopify-embed {
  --border-radius: 8px;
  background-color: #ff424d0f;
  border-radius: var(--border-radius);
  padding: 16px 24px;
  margin-bottom: 20px;
  width: 100%;

  &__header {
    margin-bottom: 10px;

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: 768px) {
    padding: 16px;
  }

  &__title {
    color: #ff424d;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  &__description {
    color: #092030;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 0;
  }

  ul {
    background-color: #ff424d1a;
    margin: 0;
    padding: 8px 16px 8px 28px;
    border-radius: var(--border-radius);

    li {
      margin: 0;
      line-height: 20px;
      font-size: 14px;
      color: #092030;
    }
  }

  .spinner .path {
    stroke-width: 7px;
    stroke: #ff424d;
  }
}
</style>
