export default {
  namespaced: true,

  state: () => ({
    isLoading: false
  }),

  mutations: {
    updateStatus(state, isLoading) {
      state.isLoading = isLoading
    }
  },

  getters: {
    getStatus(state) {
      return state.isLoading
    }
  }
}
