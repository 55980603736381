<template>
  <div class="form-area">
    <div class="header">
      <img width="130" height="56" loading="lazy" src="images/svg/logo.svg" alt="site logo" />
    </div>
    <div class="main-text">
      <h3>{{ $t('title.forgot_password') }}</h3>
    </div>
    <p class="supporting-text text-center text-grey">{{ $t('forgot_password.we_will_send_you_instructions') }}</p>
    <div class="main-form">
      <form @submit.prevent="loginUser">
        <div class="input-field__wrapper">
          <label for="email">{{ $t('forms.email') }}</label>
          <input type="text" name="email" v-model="form.email" @blur="$v.form.email.$touch()" :class="{ error: $v.form.email.$error }" />
          <div v-if="$v.form.email.$dirty">
            <span class="input-field__message error" v-if="!$v.form.email.email">{{ $t('validation.invalid.email') }}</span>
            <span class="input-field__message error" v-if="!$v.form.email.required">{{ $t('validation.required.email') }}</span>
          </div>
          <div v-if="errors && errors.email">
            <span class="input-field__message error" v-for="message in errors.email" :key="message">{{ message }}</span>
          </div>
        </div>

        <button class="button--full button--darker" :disabled="disabled">
          {{ $t('buttons.send_reset_link') }}
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2 9h14M9 2l7 7-7 7" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </button>
      </form>
    </div>
    <div class="secondary-actions">
      <div class="m-t-10">
        <router-link to="login" class="has--hover">{{ $t('buttons.sign_in_page') }}</router-link>
      </div>
      <div class="m-t-10">
        <router-link to="register" class="has--hover">{{ $t('buttons.create_a_free_account') }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthService } from '@/services/api/index'
import { required, email } from 'vuelidate/lib/validators'

export default {
  metaInfo: {
    title: 'Forgot Password | Chaty'
  },

  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  data() {
    return {
      form: {
        email: ''
      },
      isLoading: false,
      errors: null
    }
  },
  computed: {
    disabled() {
      if (this.$v.form.$invalid || this.isLoading) {
        return true
      }
      return false
    }
  },
  methods: {
    async loginUser() {
      this.isLoading = true
      this.error = null

      this.$v.form.$touch()
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return false

      try {
        await new AuthService(this).sendPasswordResetLink({ email: this.form.email })

        this.isLoading = false

        this.$store.commit('toast/update', { message: 'Reset link sent successfully!', type: 'success', status: true })
        await this.sleep(2000)
        this.$store.commit('toast/update', { message: 'Reset link sent successfully!', type: 'success', status: false })
        this.form.email = ''
        this.$v.$reset()
      } catch (error) {
        this.errors = error.response.data.errors ? error.response.data.errors : error.response.data
        this.isLoading = false
      }

      this.isLoading = false
    }
  }
}
</script>
