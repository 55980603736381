/* eslint-disable no-undef */
import axios from 'axios'

// Importing Cookies
import Cookies from 'js-cookie'

export const $axios = axios.create({
  // Base URL
  baseURL: process.env.VUE_APP_API_BASE_URL,

  // Custom Headers
  headers: {
    'Content-Type': 'application/json'
  }
})

$axios.interceptors.request.use(
  function (config) {
    let auth = localStorage.getItem('ch_token')
    let host = localStorage.getItem('sh_host')
    let shop = localStorage.getItem('sh_shop')

    // const auth = Cookies.get('ch_token') || localStorage.getItem('ch_token')
    if (auth) {
      config.headers.Authorization = 'Bearer ' + auth
    }

    // Shopify custom header
    if (host) {
      config.headers['shopify-host'] = host
    }

    // Shopify custom header
    if (shop) {
      config.headers['shopify-shop'] = shop
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  function (config) {
    // Change the data
    return config.data
  },
  function (error) {
    const code = parseInt(error.response && error.response.status)

    if ([401, 403].includes(code) && !window.location.pathname.includes('/login')) {
      // Cookies.remove('ch_token')

      try {
        localStorage.removeItem('ch_token')
        localStorage.removeItem('sh_host')
        Cookies.set('ch_last_visit', (location.pathname + location.search).substr(1))
      } catch (error) {
        console.log(error)
      }

      // using window location resets the store
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)
