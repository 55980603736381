export default class AuthService {
  constructor(args) {
    this.axios = args.$axios
  }

  login({ email, password }) {
    return this.axios.post(`login`, {
      email,
      password
    })
  }

  loginWix({ code, state, instanceId }) {
    return this.axios.post(`wix/oauth/overview`, {
      code,
      state,
      instanceId
    })
  }

  loginWixInstance(instance) {
    return this.axios.post(`login`, {
      instance
    })
  }

  loginThinkific({ tgid, channel }) {
    return this.axios.post(`login`, {
      tgid,
      channel
    })
  }

  signupThinkific({ code, subdomain, id_token }) {
    return this.axios.post(`thinkific/oauth/overview`, {
      code,
      subdomain,
      id_token
    })
  }

  signupSquareShop({ code, state }) {
    return this.axios.post(`square/oauth/overview`, {
      code,
      state
    })
  }

  installSquareShop() {
    return this.axios.get(`square/oauth/install`)
  }

  signupNuvemShop({ code }) {
    return this.axios.post(`nuvemshop/oauth/overview`, {
      code
    })
  }

  signupEcwid({ payload }) {
    return this.axios.post(`ecwid/oauth/overview`, {
      payload
    })
  }

  install(subdomain) {
    return this.axios.post(`thinkific/oauth/install`, {
      subdomain
    })
  }

  dudaLogin({ site_name, timestamp, sdk_url, secure_sig }) {
    return this.axios.post(`duda/oauth/overview`, {
      site_name,
      sdk_url,
      timestamp,
      secure_sig
    })
  }

  shopifyLogin({ hmac, host, shop, timestamp, code, state, is_not_embedded = false }) {
    return this.axios.post(`shopify/oauth/overview`, {
      hmac,
      host,
      shop,
      timestamp,
      code,
      state,
      is_not_embedded
    })
  }

  shopifyInstall(params) {
    return this.axios.post(`shopify/oauth/install`, params)
  }

  bigCartelSignup({ code }) {
    return this.axios.post(`big-cartel/oauth/overview`, {
      code
    })
  }

  bigCartelInstall() {
    return this.axios.get(`big-cartel/oauth/install`)
  }

  bigCommerceLogin({ signed_payload, signed_payload_jwt }) {
    return this.axios.post(`big-commerce/oauth/overview`, {
      ...(signed_payload && { signed_payload }),
      ...(signed_payload_jwt && { signed_payload_jwt })
    })
  }

  bigCommerceInstall({ account_uuid, code, context, scope }) {
    return this.axios.post(`big-commerce/oauth/install`, {
      ...(account_uuid && { account_uuid }),
      ...(code && { code }),
      ...(context && { context }),
      ...(scope && { scope })
    })
  }

  webFlowLogin({ code, state }) {
    return this.axios.post(`webflow/oauth/overview`, {
      ...(code && { code }),
      ...(state && { state })
    })
  }

  webFlowInstall() {
    return this.axios.get(`webflow/oauth/install`)
  }

  shoptetCzechLogin({ code, eshopId }) {
    return this.axios.post(`shoptet-cz/oauth/login`, {
      ...(code && { code }),
      ...(eshopId && { eshopId })
    })
  }

  shoptetCzechInstall() {
    return this.axios.get(`shoptet-cz/oauth/install`)
  }

  shoptetHungaryLogin({ code, eshopId }) {
    return this.axios.post(`shoptet-hu/oauth/login`, {
      ...(code && { code }),
      ...(eshopId && { eshopId })
    })
  }

  shoptetHungaryInstall() {
    return this.axios.get(`shoptet-hu/oauth/install`)
  }

  shoptetSlovakiaLogin({ code, eshopId }) {
    return this.axios.post(`shoptet-sk/oauth/login`, {
      ...(code && { code }),
      ...(eshopId && { eshopId })
    })
  }

  shoptetSlovakiaInstall() {
    return this.axios.get(`shoptet-sk/oauth/install`)
  }

  gainAccess({ code, user_identifier }) {
    return this.axios.post(`gain-access/user/${user_identifier}/code/${code}`)
  }

  register({ email, src = 'chaty', landing_url = '', referrer_url = '' }) {
    return this.axios.post(`register`, {
      email,
      src,
      landing_url,
      referrer_url
    })
  }

  sendPasswordResetLink({ email }) {
    return this.axios.post(`password/email`, {
      email
    })
  }

  resetPassword({ token, password, password_confirmation, email }) {
    return this.axios.post(`password/reset`, {
      token,
      password,
      password_confirmation,
      email
    })
  }

  changePassword({ current_password, password, password_confirmation }) {
    return this.axios.post(`password/update`, {
      current_password,
      password,
      password_confirmation
    })
  }

  createChatwayAccount() {
    return this.axios.post(`chatway/oauth/register`)
  }

  checkForChatwayEmail() {
    return this.axios.post(`chatway/oauth/verify-email`)
  }

  chatwayAutoLogin() {
    return this.axios.post(`chatway/oauth/user`)
  }

  logout() {
    /**
     * allow iframe user to log out from iframe
     * @example implemented in prestashop iframe
     */
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage('chaty/logout_from_iframe', '*')
    }

    sessionStorage.removeItem('isAdmin')
    localStorage.removeItem('sh_host')
    localStorage.removeItem('sh_shop')
    localStorage.removeItem('site_name')
    return localStorage.removeItem('ch_token')
  }
  /**
   * @param {string} provider
   */
  getProviderURL(provider) {
    return this.axios.get(`${provider}/oauth/install`)
  }

  authViaProvider(provider, params = {}) {
    return this.axios.post(`${provider}/oauth/overview`, params)
  }
}
