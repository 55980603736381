export default class LeadsService {
  constructor(args) {
    this.axios = args.$axios
  }
  getLeads({ limit, domain_url, widget_name, start_date, end_date, page }) {
    return this.axios.get(`contactform/leads`, {
      params: {
        ...(limit && { limit }),
        ...(domain_url && { domain_url }),
        ...(widget_name && { widget_name }),
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
        ...(page && { page })
      }
    })
  }

  getLeadsFilters() {
    return this.axios.get(`contactform/leads/filters`)
  }

  deleteLead(ids) {
    return this.axios.delete(`contactform/leads`, {
      data: {
        lead_ids: [...ids]
      }
    })
  }

  exportLeads({ domain_url, widget_name, lead_ids }) {
    return this.axios.get(`contactform/leads/export`, {
      params: {
        ...(domain_url && { domain_urls: domain_url }),
        ...(widget_name && { widget_names: widget_name }),
        ...(lead_ids && { lead_ids: lead_ids })
      }
    })
  }
}
