import Cookies from 'js-cookie'
import chatyStore from '@/utils/chaty-store'

export default function (to) {
  if (to && to.query.instance) {
    chatyStore.setItem('ch_wix_instance', to.query.instance)
    if (to && to.path) {
      chatyStore.removeItem('ch_token')
      Cookies.set('ch_last_visit', to.path)
    }
    window.location = '/wix/login'
    return false
  }
  return true
}
