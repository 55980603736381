<template>
  <div class="chaty-select" :class="{ active: isOpen }">
    <div class="chaty-select__wrapper">
      <div class="dropdown" :class="{ active: isOpen }" v-on-clickaway="toggleState">
        <div class="input-field__wrapper" @click.stop="isOpen = !isOpen">
          <input type="text" :class="{ 'has--icon': hasIcon }" name="select" readonly :value="displayValue" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="hasIcon" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 4H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM16 2v4M8 2v4M3 10h18" stroke="currentColor" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>

        <transition name="slow">
          <div class="dropdown__body" v-if="isOpen">
            <ul>
              <li v-for="item in list" :key="item.value">
                <a :class="{ active: selectedItem && selectedItem.value == item.value }" href="#" @click.prevent="selectItem(item)">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div class="calender--wrapper">
        <flat-pickr :config="config" id="timeZoneStartDate" type="hidden" @on-close="changeDate" placeholder="1/1/2020" ref="datePickerWrap" v-model="customDate"></flat-pickr>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

import { mixin as clickaway } from 'vue-clickaway'
export default {
  mixins: [clickaway],
  data() {
    return {
      hasIcon: true,
      isOpen: false,
      selectedItem: null,
      list: [
        {
          name: 'Today',
          value: 'today'
        },
        {
          name: 'Yesterday',
          value: 'yesterday'
        },
        {
          name: 'This week',
          value: 'this_week'
        },
        {
          name: 'Last 7 days',
          value: '7_days'
        },
        {
          name: 'This month',
          value: 'this_month'
        },
        {
          name: 'Last 30 days',
          value: 'last_30_days'
        },
        {
          name: 'Last month',
          value: 'last_month'
        },
        {
          name: 'All time',
          value: 'all_time'
        },
        {
          name: 'Custom',
          value: 'custom'
        }
      ],
      customDate: null,
      config: {
        altFormat: 'M j, Y',
        altInput: true,
        wrap: true,
        maxDate: new Date(),
        mode: 'range'
      }
    }
  },
  computed: {
    displayValue() {
      if (this.selectedItem.value == 'custom' && this.customDate) {
        return this.customDate
      }
      return this.selectedItem ? this.selectedItem.name : ''
    }
  },
  methods: {
    toggleState() {
      this.isOpen = false
    },
    selectItem(item) {
      if (item.value == 'custom') {
        this.showCalender(item)
        return
      }
      switch (item.value) {
        case 'today':
          this.$emit('changeDate', {
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD')
          })
          break
        case 'yesterday':
          this.$emit('changeDate', {
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
          })
          break
        case 'this_week':
          this.$emit('changeDate', {
            startDate: dayjs().weekday(0).format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD')
          })
          break
        case '7_days':
          this.$emit('changeDate', {
            startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD')
          })
          break
        case 'this_month':
          this.$emit('changeDate', {
            startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD')
          })
          break
        case 'last_30_days':
          this.$emit('changeDate', {
            startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD')
          })
          break
        case 'last_month':
          this.$emit('changeDate', {
            startDate: dayjs(
              dayjs()
                .month(new Date().getMonth() - 1)
                .format('YYYY-MM-DD')
            )
              .startOf('month')
              .format('YYYY-MM-DD'),
            endDate: dayjs(
              dayjs()
                .month(new Date().getMonth() - 1)
                .format('YYYY-MM-DD')
            )
              .endOf('month')
              .format('YYYY-MM-DD')
          })
          break
        case 'all_time':
          this.$emit('changeDate', {
            startDate: null,
            endDate: null
          })
          break
      }
      this.selectedItem = item
      this.isOpen = false
    },
    showCalender(item) {
      this.isOpen = false
      this.selectedItem = item
      document.querySelector('.calender--wrapper .form-control').click()
    },
    changeDate(selectedDates, dateStr) {
      if (!selectedDates) return
      const startDate = dateStr.split('to')[0].trim()
      const endDate = dateStr.split('to').length > 1 ? dateStr.split('to')[1].trim() : dateStr.split('to')[0].trim()
      this.$emit('changeDate', {
        startDate,
        endDate
      })
      document.querySelector('.calender--wrapper .form-control').blur()
    }
  },
  created() {
    // Last 7 days
    this.selectedItem = this.list[3]
  }
}
</script>

<style scoped>
.calender--wrapper {
  visibility: hidden;
  position: absolute;
  top: 0;
}
</style>
