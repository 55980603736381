<template>
  <div class="auto-fill-input-wrapper">
    <div class="main-input">
      <slot></slot>
    </div>
    <input v-if="autoCompleteVisible && !suggesationVisible" class="placeholder-input" type="email" name="email" disabled :value="autoCompleteEmail" />
    <p v-if="suggesationVisible" class="missspelled-area">
      <span class="missspelled-label">Did you mean</span>
      <span class="missspelled-text" @click.prevent="confirmSuggestedEmail">{{ confirmedEmail }}?</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'AutoFillMail',
  data: () => ({
    domains: [
      {
        name: 'gmail.com',
        autoCompleteAfter: 1, // define the number of characters to be typed before auto complete
        misspelledVariations: [
          'gmial.com',
          'gmai.com',
          'gamil.com',
          'gmal.com',
          'gamil.com',
          'gmail.cm',
          'mgail.com',
          'gmil.com',
          'gmail.om',
          'gmaill.com',
          'gmi.com',
          'gmail.co',
          'gmal.com',
          'gmali.com',
          'gml.com',
          'gmal.com',
          'gmali.com',
          'gma.com'
        ]
      },
      {
        name: 'yahoo.com',
        autoCompleteAfter: 2
      },
      {
        name: 'icloud.com',
        autoCompleteAfter: 2
      },
      {
        name: 'me.com',
        autoCompleteAfter: 2
      },
      {
        name: 'mac.com',
        autoCompleteAfter: 2
      },
      {
        name: 'outlook.com',
        autoCompleteAfter: 2
      },
      {
        name: 'protonmail.com',
        autoCompleteAfter: 2
      },
      {
        name: 'zohocorp.com',
        autoCompleteAfter: 2
      },
      {
        name: 'gmx.com',
        autoCompleteAfter: 2
      },
      {
        name: 'aol.com',
        autoCompleteAfter: 2
      },
      {
        name: 'hotmail.com',
        autoCompleteAfter: 2
      },
      {
        name: 'aoicorp.com',
        autoCompleteAfter: 2
      },
      {
        name: 'premio.io',
        autoCompleteAfter: 2
      }
    ],
    autoCompleteVisible: false,
    suggesationVisible: false,
    confirmedEmail: '',
    autoCompleteEmail: ''
  }),
  props: {
    value: {
      type: String,
      default: '',
      required: true
    }
  },
  watch: {
    value() {
      const { domain, username } = this.extractDomain()
      if (domain && username) {
        this.autoCompleteHandler()
      } else {
        this.autoCompleteVisible = false
      }

      this.confirmedEmail = ''
      this.suggesationVisible = false
    }
  },
  methods: {
    /**
     * @returns {{
     *  username?: string,
     *  domain?: string
     * }}
     */
    extractDomain() {
      const [username, domain] = this.value.split('@')
      return {
        username,
        domain: domain
      }
    },
    /**
     * @return {boolean}
     */
    isEmailComplete() {
      const domainObject = this.domains.filter(item => this.value.toLowerCase().includes(item.name))
      return domainObject.length > 0
    },

    /**
     * confirmAutoComplete is called when user press
     * enter, tab or space, right arrow
     * @returns {void}
     */
    confirmAutoComplete(ev) {
      if (this.autoCompleteVisible) {
        ev.preventDefault()

        this.$emit('update:value', this.autoCompleteEmail.trim())
        this.autoCompleteVisible = false
        this.suggesationVisible = false
      }
    },
    /**
     * confirmSuggestedEmail is called when user click on the suggested email
     * @returns {void}
     */
    confirmSuggestedEmail() {
      this.$emit('update:value', this.confirmedEmail)
      this.autoCompleteVisible = false
      this.suggesationVisible = false
    },

    hasUppercaseCharacter(str) {
      for (let i = 0; i < str.length; i++) {
        if (str[i] !== str[i].toLowerCase()) {
          return true // Found an uppercase character
        }
      }
      return false // No uppercase characters found
    },

    /**
     * checkSuggesation is called when submit the form
     * @returns {boolean}
     */
    checkSuggesation() {
      if (this.confirmedEmail.length > 0 && !this.autoCompleteVisible) {
        this.suggesationVisible = false
        return false
      }

      const { domain } = this.extractDomain()
      /**
       * @const {object|[]} suggestedDomain
       * @property {string} name
       * @property {string[]} misspelledVariations
       * @property {number} autoCompleteAfter
       */
      const suggestedDomain =
        this.domains.filter(item => {
          if (item.name === domain.toLowerCase().trim()) return
          // if the domain has no uppercase character, we should check the misspelled variations
          if (!Array.isArray(item.misspelledVariations)) return
          return item.misspelledVariations.includes(domain.toLowerCase())
        })[0] || false

      if (suggestedDomain) {
        this.confirmedEmail = `${this.extractDomain().username}@${suggestedDomain.name}`
        this.suggesationVisible = true
      }

      return this.suggesationVisible
    },

    /**
     * autoCompleteHandler is called when user type in the input
     * @returns {void}
     */
    autoCompleteHandler() {
      if (this.isEmailComplete()) {
        this.autoCompleteVisible = false
        this.suggesationVisible = false
        return
      }

      const { shouldShow, email } = this.getAutoCompleteEmail()
      this.autoCompleteEmail = email
      this.autoCompleteVisible = shouldShow
    },

    /**
     * get the valid domain which is closed to the user input
     * @returns {string|boolean}
     */
    getDomain() {
      const { domain } = this.extractDomain()
      return this.domains.filter(item => item.name.startsWith(domain.toLowerCase()))[0] || false
    },

    /**
     * getAutoCompleteEmail is called when user type in the input
     * @returns {{
     *  shouldShow: boolean,
     *  email: string
     * }}
     */
    getAutoCompleteEmail() {
      const { domain, username } = this.extractDomain()
      const validDomain = this.getDomain()
      if (validDomain && domain.length >= validDomain.autoCompleteAfter && domain.length <= validDomain.name.length) {
        let email = ''
        /**
         * @const domain might contain uppercase character
         * @const validDomain.name is always lowercase
         * - need to make validDomain.name case according to the user input like domain variable
         */
        if (this.hasUppercaseCharacter(domain)) {
          const [, restPart] = validDomain.name.split(domain.toLowerCase())
          email = `${username}@${domain}${restPart}`
        } else {
          email = `${username}@${validDomain.name}`
        }

        return {
          shouldShow: true,
          email
        }
      } else {
        return {
          shouldShow: false,
          email: this.value
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.auto-fill-input-wrapper {
  position: relative;

  .main-input {
    position: relative;
    z-index: 1;
  }

  .placeholder-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }

  .missspelled-area {
    font-size: 14px;
  }

  .missspelled-label {
    color: #ff424d;
    display: inline-block;
    margin-right: 4px;
  }

  .missspelled-text {
    color: #0bacdd;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: darken(#0bacdd, 10%);
    }
  }
}
</style>
