<template>
  <div>
    <div class="widgets">
      <h4 class="page-header">{{ $t('widgets.title') }}</h4>
      <div class="cta grid middle-xs between-xs m-b-10 widget-listings">
        <ChatySelectComponent :list="list" :hasIcon="true" />
        <button @click="$refs.createWidgetModal.open()" type="button" class="button button--has-svg-left text-500">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.833 2.5H4.167c-.92 0-1.667.746-1.667 1.667v11.666c0 .92.746 1.667 1.667 1.667h11.666c.92 0 1.667-.746 1.667-1.667V4.167c0-.92-.746-1.667-1.667-1.667zM10 6.667v6.666M6.667 10h6.666" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ $t('create_widget.new_widget') }}
        </button>
      </div>
      <DomainComponent :domain="domain" v-for="domain in domains" :key="domain.id" />
    </div>

    <ShopifyEmbedAppPlaceholder>
      <img src="@/assets/images/empty-state/home.png" alt="" />
      <p>
        {{ $t('widgets.no_widget') }}
      </p>
      <div class="buttons">
        <a @click.prevent="$refs.YouTubeModal.open()" href="#" class="button button--has-svg-left button--outline">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4.167 2.5L15.833 10 4.167 17.5v-15z" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ $t('widgets.watch_tutorial') }}
        </a>
        <button @click="$refs.createWidgetModal.open()" type="button" class="button button--has-svg-left text-normal">{{ $t('widgets.create_widget') }}</button>
      </div>
    </ShopifyEmbedAppPlaceholder>
    <CreateWidgetModal ref="createWidgetModal" />
    <YouTubeModal ref="YouTubeModal" />
  </div>
</template>

<script>
import ChatySelectComponent from '@/components/global/ChatySelect.vue'
import CreateWidgetModal from '@/components/modals/CreateWidget.vue'
import DomainComponent from '@/components/widgets/DomainComponent.vue'
import YouTubeModal from '@/components/modals/YouTubeModal.vue'
import ShopifyEmbedAppPlaceholder from './ShopifyEmbedAppPlaceholder.vue'

export default {
  components: {
    ChatySelectComponent,
    DomainComponent,
    CreateWidgetModal,
    YouTubeModal,
    ShopifyEmbedAppPlaceholder
  },
  data() {
    return {
      domains: [
        {
          url: 'jstrainer.com',
          total_visits: 98,
          total_clicks: 1,
          click_rate: 1.0204081632653061,
          widgets: [
            {
              name: 'test widget Avi',
              is_first_widget: false,
              identifier: '1chlej1jgk',
              user_id: 'SUg5hrs4',
              domain: 'jstrainer.com',
              state: 'active',
              total_mobile_views: 1,
              total_mobile_clicks: 0,
              mobile_click_rate: 0,
              total_desktop_views: 41,
              total_desktop_clicks: 0,
              desktop_click_rate: 0,
              total_views: 42,
              total_clicks: 0,
              click_rate: 0,
              channel_list: ['WhatsApp', 'Facebook_Messenger'],
              is_default_open: false,
              published: true,
              created_at: '2021-11-09T11:07:14.000000Z'
            },
            {
              name: 'Premio',
              is_first_widget: false,
              identifier: '6bssk5argg',
              user_id: 'SUg5hrs4',
              domain: 'jstrainer.com',
              state: 'active',
              total_mobile_views: 3,
              total_mobile_clicks: 0,
              mobile_click_rate: 0,
              total_desktop_views: 51,
              total_desktop_clicks: 1,
              desktop_click_rate: 1.9607843137254901,
              total_views: 54,
              total_clicks: 1,
              click_rate: 1.8518518518518519,
              channel_list: ['Google_Map', 'Vkontakte', 'WhatsApp'],
              is_default_open: false,
              published: true,
              created_at: '2021-11-09T12:28:42.000000Z'
            }
          ]
        },
        {
          url: 'test.com',
          total_visits: 0,
          total_clicks: 0,
          click_rate: 0,
          widgets: [
            {
              name: 'test validation',
              is_first_widget: false,
              identifier: 'eg93idhi4t',
              user_id: 'SUg5hrs4',
              domain: 'test.com',
              state: 'draft',
              total_mobile_views: 0,
              total_mobile_clicks: 0,
              mobile_click_rate: 0,
              total_desktop_views: 0,
              total_desktop_clicks: 0,
              desktop_click_rate: 0,
              total_views: 0,
              total_clicks: 0,
              click_rate: 0,
              channel_list: [],
              is_default_open: false,
              published: null,
              created_at: '2021-11-18T13:30:43.000000Z'
            }
          ]
        },
        {
          url: 'test.url',
          total_visits: 0,
          total_clicks: 0,
          click_rate: 0,
          widgets: [
            {
              name: 'Intranet',
              is_first_widget: false,
              identifier: '23ctqabp0r',
              user_id: 'SUg5hrs4',
              domain: 'test.url',
              state: 'active',
              total_mobile_views: 0,
              total_mobile_clicks: 0,
              mobile_click_rate: 0,
              total_desktop_views: 0,
              total_desktop_clicks: 0,
              desktop_click_rate: 0,
              total_views: 0,
              total_clicks: 0,
              click_rate: 0,
              channel_list: ['WhatsApp', 'Facebook_Messenger'],
              is_default_open: false,
              published: true,
              created_at: '2021-11-15T14:09:45.000000Z'
            },
            {
              name: 'validate channel empty',
              is_first_widget: false,
              identifier: 'wlje9j603p',
              user_id: 'SUg5hrs4',
              domain: 'test.url',
              state: 'active',
              total_mobile_views: 0,
              total_mobile_clicks: 0,
              mobile_click_rate: 0,
              total_desktop_views: 0,
              total_desktop_clicks: 0,
              desktop_click_rate: 0,
              total_views: 0,
              total_clicks: 0,
              click_rate: 0,
              channel_list: ['WhatsApp'],
              is_default_open: false,
              published: true,
              created_at: '2021-11-18T13:31:18.000000Z'
            }
          ]
        }
      ],
      list: [
        {
          name: 'Today',
          value: 'today'
        },
        {
          name: 'Yesterday',
          value: 'yesterday'
        },
        {
          name: 'This week',
          value: 'this_week'
        },
        {
          name: 'Last 7 days',
          value: '7_days'
        },
        {
          name: 'This month',
          value: 'this_month'
        },
        {
          name: 'Last 30 days',
          value: 'last_30_days'
        },
        {
          name: 'Last month',
          value: 'last_month'
        },
        {
          name: 'All time',
          value: 'all_time'
        },
        {
          name: 'Custom',
          value: 'custom'
        }
      ]
    }
  }
}
</script>

<style scoped>
.widgets {
  max-height: calc(100vh - 120px);
  overflow: hidden;
}
</style>
